import React, { useContext, useEffect, useState } from 'react';
import config from "../../Services/config.js";
import Instance from '../../Services/Instance';
import { DataContext } from '../../Services/DataContext.js';
import { Link } from 'react-router-dom';
import deleteProduct from "../../Assets/Imgs/icons/icon-delet-item.svg";
import "../../Assets/Styles/Cart.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function ShowCart() {
    const { cartItems, removeFromCart, updateQuantity, showCart, setShowCart } = useContext(DataContext);

    const [detailedCartItems, setDetailedCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [paymentInProgress, setPaymentInProgress] = useState(false);


    // Fonction pour récupérer les détails des produits à partir de l'API
    const fetchProductDetails = async () => {
        try {
            const productPromises = cartItems.map(async (item) => {
                const result = await Instance.get(`${config.api_url}/show-product/${item.productId}`);
                return {
                    ...result.data.product,
                    quantity: item.quantity,
                    totalItemPrice: item.totalItemPrice
                };
            });

            const products = await Promise.all(productPromises);
            setDetailedCartItems(products);
        } catch (err) {
            setError('Erreur lors de la récupération des produits.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (cartItems.length > 0) {
            fetchProductDetails();
        } else {
            setDetailedCartItems([]);
            setLoading(false);
        }
    }, [cartItems]);



    // Incrémentation de la quantité
    const increment = (productId) => {
        const item = cartItems.find(item => item.productId === productId);
        if (item && item.quantity < 3) {
            updateQuantity(productId, item.quantity + 1);
        }
    };

    // Décrémentation de la quantité
    const decrement = (productId) => {
        const item = cartItems.find(item => item.productId === productId);
        if (item && item.quantity > 1) {
            updateQuantity(productId, item.quantity - 1);
        }
    };

    // Supprimer le produit du panier
    const handleRemove = (productId) => {
        removeFromCart(productId);
    };

    //Show cart
    const handleShowCart = () => {
        setShowCart(false)
    }
    window.scroll(0, 0)
    // if ((detailedCartItems.length === 0 )) {
    //     setShowCart(false)
    // }

    return (
        <div className='show-cart'>
            <div className='show-cart-content'>
                <div className='cart-container-show-cart'>
                    {detailedCartItems.length === 0 ? (
                        <div>
                            <p>Votre panier est vide.</p>
                            <Link to={""} onClick={handleShowCart} className='btn-show-cart' id='btn-buy-now'>Faire un Achat</Link>
                        </div>

                    ) : (
                        <ul>
                            {detailedCartItems.map((item) => (
                                <li key={item._id} className='container-product-cart ' id='container-product-cart-popup'>
                                    <div className='details-product-cart'>
                                        <img src={`${config.img_url}/${item.productImage}`} alt='img-product' id='img-cart' />
                                        <div className='product-cart-infos'>
                                            <div className='product-cart-name'>
                                                <h4> {item.productName} </h4>
                                                <h4> {item.productPrice} F CFA </h4>
                                            </div>
                                            <div className='product-cart-quantity-popup'>
                                                <p id='qty-cart'>Quantité</p>
                                                <div className='counter-cart-popup'>
                                                    <button type="button" onClick={() => decrement(item._id)} className='counter-cart-popup'>-</button>
                                                    <button type="button" className='counter-cart' id='quantity-cart-popup'>{item.quantity}</button>
                                                    <button type="button" onClick={() => increment(item._id)} className='counter-cart-popup'>+</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='content-trash-cart'>
                                        <FontAwesomeIcon icon={faTrash} onClick={() => handleRemove(item._id)} className='icon-delete-cart' />
                                    </div>                                </li>
                            ))}
                            <div className='redirect-show-cart'>
                                <Link to={""} onClick={handleShowCart} className='btn-show-cart' id='btn-buy-now'>Continuer Mes Achats</Link>
                                <Link to="/cart" className='btn-show-cart'>Finaliser la Commande</Link>
                            </div>

                        </ul>
                    )}
                </div>

            </div>

        </div>
    )
}

export default ShowCart