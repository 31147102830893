import React, { useContext } from 'react'
import { Link } from "react-router-dom"
import "../../Assets/Styles/Home.css"
import { DataContext } from "../../Services/DataContext"
import iconCheck from "../../Assets/Imgs/icons/icon-check.png"
function SubShare() {
  const { isBooleanValue } = useContext(DataContext);

  const handleLinkClick = () => {
    // Afficher une alerte si l'utilisateur n'est pas connecté
    alert("Vous devez vous connecter pour partager un abonnement");
    window.location = "/signin"
  }
  return (
    <div className="sub-share">
      <div className='sub-share-elements sub'>
        <div className='sub-text'>
          <h2>Rejoindre un abonnement</h2>
          <div className='sub-para'> <img src={iconCheck} alt='icon-check' className='check-mark'/> <span > Souscrivez à un abonnement et bénéficiez d'économies allant jusqu'à 70% chaque mois. </span></div>
          <div className='sub-para'> <img src={iconCheck} alt='icon-check' className='check-mark'/> <span > Accédez immédiatement à vos services </span></div>
          <div className='sub-para'> <img src={iconCheck} alt='icon-check' className='check-mark'/> <span > Une question ou un souci ? Notre équipe de support client est à votre disposition 24/7 pour vous aider. </span></div>
          <Link to="/products" className='btn-sub-share'> Rejoignez un abonnement  <span className='go-arrow'>{'>'}</span> </Link>
        </div>
      </div>
      <div className='sub-share-elements shares'>
        <div className='sub-text'>
          <h2>Partager un abonnement</h2>
          <div className='sub-para'> <img src={iconCheck} alt='icon-check' className='check-mark'/> <span > Mettez à disposition les places disponibles de vos abonnements en toute simplicité</span></div>
          <div className='sub-para'> <img src={iconCheck} alt='icon-check' className='check-mark'/> <span > Récupérez jusqu’à 80% du montant mensuel de votre abonnement. </span></div>
          <div className='sub-para'> <img src={iconCheck} alt='icon-check' className='check-mark'/> <span > Recevez vos paiements tous les 30 jours. </span></div>

          {
            isBooleanValue && <Link to="/share" className='btn-sub-share'> Partager un abonnement <span className='go-arrow'>{'>'}</span></Link>
          }
          {
            !isBooleanValue && <Link to="" className='btn-sub-share' onClick={handleLinkClick}> Partager un abonnement <span className='go-arrow'>{'>'}</span></Link>
          }
        </div>
      </div>
    </div>
  )
}

export default SubShare