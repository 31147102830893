import React, { useEffect, useState } from 'react';
import '../../Assets/Styles/Compte.css'; // Fichier CSS pour le style
import Instance from '../../Services/Instance';
import config from '../../Services/config.js';
import { decryptData } from '../../Services/EncryptData.js';

function EditInfos() {
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        numberPhone: "",
        email: "",
    });

    const user__ = localStorage.getItem("user");
    const dataUser__ = user__ === null ? null : decryptData(user__);

    const fetchData = async () => {
        try {
            const result = await Instance.get(`${config.api_url}/show-user-by-email/${dataUser__.email}`);
            setUser(result.data.user);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [dataUser__._id]);

    const {firstName , lastName , numberPhone , email} = user

    const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await Instance.put(`${config.api_url}/update-user/${user._id}`, user);
            window.location = `/account`;
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="edit-user">
            <div className="edit-header">
                <h2>Editer les informations</h2>
            </div>

            <form onSubmit={handleSubmit} className="edit-user-form">
                <div className="form-section">
                    <div className="form-group">
                        <label htmlFor="firstName">Prénom</label>
                        <input
                            className="input"
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={firstName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Nom</label>
                        <input
                            className="input"
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={lastName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>

                <div className="form-group form-section">
                    <label htmlFor="email">Adresse e-mail</label>
                    <input
                        className="input"
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group form-section">
                    <label htmlFor="numberPhone">Numéro de téléphone</label>
                    <input
                        className="input"
                        type="text"
                        id="numberPhone"
                        name="numberPhone"
                        value={numberPhone}
                        onChange={handleChange}
                        required
                    />
                </div>
                

                <div className="">
                    <button type="submit" className="btn-save">Sauvegarder</button>
                </div>
            </form>
        </div>
    );
}

export default EditInfos;
