import React, { useState } from 'react';
import HowShare from '../Shares/HowShare'
import HowSubscription from '../Subscriptions/HowSubscription'
import "../../Assets/Styles/Home.css"


function HowItWorks() {
  const [activeButton, setActiveButton] = useState(2);

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };
  return (
    <div className='How-it-works'>
      <div className='title'>
        <h1> <span id='how'>Comment</span> ça marche <span id='how'> ?</span> </h1>
        <p>En seulement 4 étapes simples </p>

      </div>

      <div className="choice-option">
        <button className='btn-choice-option' id={activeButton === 2 ? "btn-choice-option-active" : ""} onClick={() => handleButtonClick(2)}>  Rejoindre un abonnement</button>
        <button className='btn-choice-option' id={activeButton === 1 ? "btn-choice-option-active" : ""} onClick={() => handleButtonClick(1)}> Partager un abonnement </button>
      </div>

      {activeButton === 1 ? <HowShare /> : <HowSubscription />}

    </div>
  )
}

export default HowItWorks