import React, { useState, useEffect } from 'react';
import Instance from '../../Services/Instance';
import config from '../../Services/config.js';
import { Link, useParams } from 'react-router-dom';
import '../../Assets/Styles/Connection.css';
import login from "../../Assets/Imgs/logoLogin.png";

const ResetPassWord = () => {
    const [data, setData] = useState({ password: '', confirmPassWord: "" });
    const [error, setError] = useState('');
    const [resReq, setResReq] = useState('');

    const { resetToken } = useParams()
    // console.log(resetToken)
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    // console.log(data);
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const url = `${config.api_url}/reset-password/${resetToken}`;
            const { data: response } = await Instance.post(url, data);

            if (response) {
                const message = response.message
                setResReq(message)
                window.location = '/signin';
            }
        } catch (error) {
            console.error('Erreur lors de la connexion:', error);
            setError(error);
        }
    };

    return (
        <div class="container-login">
            <div class="left-section-login">
                <form onSubmit={handleSubmit}>
                    <div>
                        <h1>Nuuveau mot de passe</h1>
                        <p>Pour continuer, veuillez fournir les détails requis ci-dessous.</p>
                    </div>
                    <div className="parent-form-input">
                        <label htmlFor="password">Nouveau Mot de PAsse</label>
                        <input
                            className='input'
                            type='password'
                            name='password'
                            id='password'
                            value={data.password}
                            onChange={handleChange}
                            required
                            placeholder="Entrer un nouveau Mot de passe"

                        />
                    </div>
                    <div className="parent-form-input">
                        <label htmlFor='password'> Confirmez le Nouveau Mot de passe:</label>
                        <input
                            className='input'
                            type='password'
                            name='confirmPassWord'
                            id='confirmPassWord'
                            value={data.confirmPassWord}
                            onChange={handleChange}
                            required
                            placeholder="Confirmer le nouveau Mot de passe"

                        />
                        {
                            data.confirmPassWord && data.confirmPassWord !== data.password &&
                            (
                                <h6 id='confirm-email'>Vous avez renseignez des emails différents</h6>
                            )
                        }
                    </div>
                    <button type="submit" class="btn">
                        Réinitialiser le mot de passe
                    </button>

                </form>
            </div>

            <div class="right-section-login">
                <div class="image-container">
                    <img src={login} alt="Senpremium Design" />
                </div>
                <h2>+100 Services Disponibles</h2>
                <p id="para-login">
                    Rejoignez la meilleure application d'abonnements et profitez pleinement de vos services préférés en partageant ou rejoignant un service.
                    Spotify, Netflix, Amazon, Scribd, Appel Music, Mircosoft 365, Udemy, Iptv, etc...
                </p>
            </div>
        </div>
    );
};


export default ResetPassWord

/*

<div className='layout-signin'>
            <div className='logo'>
                <Link to='/'>
                    <img src={logo} alt='logo' />
                </Link>
            </div>
            <div className='form-req-reset-password'>
                {!resReq &&
                    <form onSubmit={handleSubmit}>
                        <div className='parent-form-input'>
                            <label htmlFor='password'>Nouveau Mot de passe:</label>
                            <input
                                className='input'
                                type='password'
                                name='password'
                                id='password'
                                value={data.password}
                                onChange={handleChange}
                                required
                                placeholder="Entrer un nouveau Mot de passe"

                            />
                        </div>
                        <div className='parent-form-input'>
                            <label htmlFor='password'> Confirmez le Nouveau Mot de passe:</label>
                            <input
                                className='input'
                                type='password'
                                name='confirmPassWord'
                                id='confirmPassWord'
                                value={data.confirmPassWord}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        {
                            data.confirmPassWord && data.confirmPassWord !== data.password &&
                            (
                                <h6 id='confirm-email'>Vous avez renseignez des emails différents</h6>
                            )
                        }


                        <Link onClick={handleSubmit} className='btn-form-connection' id='btn-resetpw'>
                            Réinitialiser le mot de passe
                        </Link>
                    </form>
                }
                {resReq &&
                    (<div>
                        <p> {resReq} </p>
                        <Link to="/signin" className='btn-form-connection' id='btn-resetpw'>
                            Se Connecter
                        </Link>
                    </div>)
                }
            </div>
            <div>
                {error && <h1 style={{ color: 'red' }}>{error.response.data.message}</h1>}
            </div>
        </div>

*/