import { createContext, useState, useEffect } from "react";
import Instance from "./Instance";
import config from "./config.js"
import { decryptData, encryptData } from "./EncryptData.js";

// Créez un contexte
const DataContext = createContext();

// Créez un provider pour envelopper vos composants et gérer les données partagées
const DataProvider = ({ children }) => {
  // console.log(config);

  // Connection (on chercher à savoir si l'utlisateur s'est conencté ou pas) 
  const [isBooleanValue, setIsBooleanValue] = useState(() => {
    // Récupérer la valeur depuis le localStorage lors de l'initialisation
    const storedValue = localStorage.getItem('isBooleanValue');
    // Utiliser la valeur stockée ou false si elle n'existe pas
    return storedValue ? JSON.parse(storedValue) : false;
  });



  const toggleBooleanValue = () => {
    // Inverser la valeur actuelle
    const updatedValue = !isBooleanValue;
    // Mettre à jour l'état
    setIsBooleanValue(updatedValue);
    // Stocker la nouvelle valeur dans le localStorage
    localStorage.setItem('isBooleanValue', JSON.stringify(updatedValue));
  };

  const [orderInformation, setOrderInformation] = useState(() => {
    // Récupérer la valeur depuis le localStorage lors de l'initialisation
    const storedValue = localStorage.getItem('orderInformation');
    // Utiliser la valeur stockée ou false si elle n'existe pas
    return storedValue ? JSON.parse(storedValue) : false;
  });

  //Subscribers
  const [products, setProducts] = useState([]);

  //cathegories 
  //cathegories 
  const [music, setMusic] = useState([]);
  const [video, setVideo] = useState([]);
  const [securite, setSecurite] = useState([]);
  const [services, setServices] = useState([]);
  const [design, setDesign] = useState([]);
  const [carteCadeauEtJeux, setCarteCadeauEtJeux] = useState([]);
  const [education, setEducation] = useState([]);
  const [logiciel, setLogiciel] = useState([]);
  const [gaming, setGaming] = useState([]);

  //Posts
  const [posts, setPosts] = useState([]);
  //Show Cart
  const [showCart, setShowCart] = useState(false);



  //Shares (formule de partage : les données de partage sont enregistré entre différents composants)
  const [formShare, setFormShare] = useState({
    shareName: '',
    shareType: '',
    shareQuantity: '',
    sharePrice: '',
    shareImg: '',
    shareEmailProduct: '',
    sharePasseWordProduct: '',
    CategoryName: '',
  });

  //handle change pour passer les données de share
  const handleChange = (name, value) => {
    // console.log(`Setting ${name} to ${value}`);
    setFormShare({ ...formShare, [name]: value });
  };

  // Une fonction pour changer le composant actif lorsque vous cliquez sur un bouton

  const [activeComponent, setActiveComponent] = useState("share-category");

  const handleChangeComponent = (componentName) => {
    setActiveComponent(componentName);
  };

  const [activeElement, setActiveElement] = useState("")
  const handleActiveElement = (element) => {
    setActiveElement(element)
  }
  const [activeItem, setActiveItem] = useState(0);
  const [activeName, setActiveName] = useState(0);

  const [error, setError] = useState("");

  useEffect(() => {
    fetchData();
    fetchDataPosts();
  }, []);

  //Products and categories
  const fetchData = async () => {
    try {
      const result = await Instance.get(`${config.api_url}/products`);

      setProducts(result.data.products);
      setProducts(result.data.products);
      setVideo(result.data.products.filter(product => product.productCategory === "Streaming Video"));
      setMusic(result.data.products.filter(product => product.productCategory === "Music"));
      setCarteCadeauEtJeux(result.data.products.filter(product => product.productCategory.includes("Cartes Cadeaux") ));
      setEducation(result.data.products.filter(product => product.productCategory === "Education"));
      setSecurite(result.data.products.filter(subscriber => subscriber.productCategory === "Securite"));
      setDesign(result.data.products.filter(subscriber => subscriber.productCategory === "Design"));
      setServices(result.data.products.filter(product => product.productCategory === "Services"));
      setLogiciel(result.data.products.filter(product => product.productCategory === "Logiciel"));
      setGaming(result.data.products.filter(product => product.productCategory === "Gaming"));

    } catch (error) {
      console.error("-+-+-+-Erreur lors de la recupération des subscribers:", error);
      setError(error);
    }

  };

  // Blog app
  const fetchDataPosts = async () => {
    try {
      const result = await Instance.get(`${config.api_url}/posts`);
      setPosts(result.data.posts);

    } catch (error) {
      console.error("-+-+-+-Erreur lors de la recupération des posts:", error);
      setError(error);
    }

  };

  // check the request new subscription (pour eviter que la requete se lance 2 fois)
  const [isRequestNewSubscriptionSent, setIsRequestNewSubscriptionSent] = useState(() => {
    // Récupérer la valeur depuis le localStorage lors de l'initialisation
    const storedValue = localStorage.getItem('isRequestNewSubscriptionSent');
    // Utiliser la valeur stockée ou false si elle n'existe pas
    return storedValue ? JSON.parse(storedValue) : false;
  });

  // const [orderInformation, setOrderInformation] = useState(false);



  //New subscription et Subscription confirmation
  const [confirmation, setConfirmation] = useState(() => {
    const storedConfirmation = localStorage.getItem("confirmation");
    return storedConfirmation ? JSON.parse(storedConfirmation) : null;
  });

  const [newOrder, setNewOrder] = useState(() => {
    const storedNewOrder = localStorage.getItem("newOrder");
    return storedNewOrder ? JSON.parse(storedNewOrder) : null;
  });


  useEffect(() => {
    if (confirmation) {
      localStorage.setItem("confirmation", JSON.stringify(confirmation));
    }
    if (newOrder) {
      localStorage.setItem("newOrder", JSON.stringify(newOrder));
    }

    if (isRequestNewSubscriptionSent) {
      localStorage.setItem("isRequestNewSubscriptionSent", JSON.stringify(isRequestNewSubscriptionSent));

    }
    if (orderInformation) {
      localStorage.setItem("orderInformation", JSON.stringify(orderInformation));
    }



  }, [confirmation, newOrder, isRequestNewSubscriptionSent, orderInformation]);



  const clearItem = () => {
    localStorage.removeItem("confirmation");
    setConfirmation(null);
    localStorage.removeItem("newOrder");
    setNewOrder(null);
    localStorage.removeItem("isRequestNewSubscriptionSent");
    setIsRequestNewSubscriptionSent(null)
    localStorage.removeItem("orderInformation");
    setOrderInformation(null)


  };

  //Logical Cart
  // Charger l'état initial du panier à partir de localStorage
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Mettre à jour localStorage à chaque fois que le panier change
  
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartItems));
  }, [cartItems]);

  // Fonction pour ajouter un produit au panier (ou mettre à jour sa quantité)
  const addToCart = (productId, quantity = 1) => {
    // setShowCart(true)
    setCartItems((prevItems) => {
      // Vérifier si le produit est déjà dans le panier
      const existingItemIndex = prevItems.findIndex((item) => item.productId === productId);

      if (existingItemIndex >= 0) {
        // Si le produit existe, mettre à jour la quantité
        const updatedItems = [...prevItems];
        const newQuantity = updatedItems[existingItemIndex].quantity + quantity;
        updatedItems[existingItemIndex] = {
          ...updatedItems[existingItemIndex],
          quantity: newQuantity,
        };
        return updatedItems;
      } else {
        // Sinon, ajouter un nouvel élément
        return [
          ...prevItems,
          {
            productId,
            quantity,
          }
        ];
      }
    });
  };

  // Fonction pour modifier la quantité d'un produit
  const updateQuantity = (productId, quantity) => {
    setCartItems((prevItems) => {
      return prevItems.map((item) =>
        item.productId === productId
          ? {
            ...item,
            quantity,
            totalItemPrice: quantity * item.productPrice
          }
          : item
      );
    });
  };

  // Fonction pour supprimer un produit du panier
  const removeFromCart = (productId) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.productId !== productId));
  };

  // Fonction pour vider le panier
  const clearCart = () => {
    localStorage.removeItem('cart');
    setCartItems([]);
  };



  return (
    <DataContext.Provider
      value={{
        isBooleanValue,
        toggleBooleanValue,

        products,
        music,
        video,
        securite,
        services,
        design,
        carteCadeauEtJeux,
        education,
        logiciel,
        gaming,

        posts,

        formShare,
        setFormShare,
        handleChange,
        activeComponent,
        setActiveComponent,
        handleChangeComponent,
        activeElement,
        setActiveElement,
        handleActiveElement,
        activeItem,
        setActiveItem, activeName,
        setActiveName,
        isRequestNewSubscriptionSent,
        setIsRequestNewSubscriptionSent,
        orderInformation,
        setOrderInformation,

        confirmation,
        setConfirmation,
        clearItem,
        newOrder,
        setNewOrder,

        cartItems,
        addToCart,
        removeFromCart,
        updateQuantity,
        clearCart,

        showCart,
        setShowCart,

        error,


      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
