import React, { useEffect, useState } from 'react';
import NavBar from '../../Layouts/NavBar';
import config from '../../Services/config.js';
import Instance from '../../Services/Instance';
import { useParams } from 'react-router-dom';
import '../../Assets/Styles/Subscriptions.css';
import levels from "../../Assets/Imgs/icons/icon-levels-subscriptions.svg"
import Footer from '../../Layouts/Footer';
import { decryptData } from '../../Services/EncryptData.js';

function Renewal() {
    // Déclaration des états
    const [error, setError] = useState('');
    const [product, setProduct] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(true);
    const [paymentInProgress, setPaymentInProgress] = useState(false); // État pour suivre l'état de la demande de paiement
    const [applyCodePromo, setApplyCodePromo] = useState("");
    const [pointsApplied, setPointsApplied] = useState(0);

    // Récupération de l'identifiant du produit depuis les paramètres de l'URL
    const { ref } = useParams();

    // Récupération de l'utilisateur depuis le stockage local
    const user__ = localStorage.getItem("user")
    const user = user__ === null ? null : decryptData(user__)


    // Effet de chargement initial pour récupérer les détails du produit
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(`${config.api_url}/show-subscription/${ref}`);
                setProduct(result.data.subscription);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        window.scrollTo(0, 0);
        fetchData();
    }, [ref]);

    // Incrémentation de la quantité
    const increment = () => {
        setQuantity(quantity + 1);
    };

    // Décrémentation de la quantité
    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };
    const handleApplyPromoOrPoints = (e, type) => {
        if (type === "promo") {
            const newPromoValue = e.target.value;
            if (applyCodePromo !== newPromoValue) {
                setApplyCodePromo(newPromoValue); // Mise à jour seulement si la valeur est différente
            }
        } else if (type === "points") {
            const newPointsValue = e.target.value;
            if (pointsApplied !== newPointsValue) {
                setPointsApplied(newPointsValue); // Mise à jour seulement si la valeur est différente
            }
        }
    };

    const winPoints = () => {
        return Math.ceil((quantity * product.subscriptionPrice) / 40)
    }

    const handleSubscriptionRequest = async () => {
        if (paymentInProgress) return; // Si la demande de paiement est déjà en cours, ne rien faire

        try {
            const orderRequestSubscription = {
                isRenewal : true ,
                subsId: product._id,
                subscriptionQuantity: quantity,
                subPoints: [winPoints(), pointsApplied],
                userId: user._id,
            }
            setPaymentInProgress(true); // Définir l'état de la demande de paiement en cours

            const subscriptionResponse = await Instance.post(`${config.api_url}/new-subscription`, [orderRequestSubscription]);
            // console.log('Subscription Response:', subscriptionResponse);
            // Vérifier si la réponse contient une URL de paiement
            if (subscriptionResponse && subscriptionResponse.data && subscriptionResponse.data.payment) {
                window.location.href = subscriptionResponse.data.payment; // Redirection vers l'URL de paiement
            } else {
                // Gérer le cas où il n'y a pas d'URL de paiement
                console.error('URL de paiement manquante dans la réponse de souscription.');
                alert('Erreur lors du paiement.'); // Afficher un message d'erreur à l'utilisateur
                window.location.reload();
            }
        } catch (error) {
            console.error('Erreur lors de la demande d\'abonnement :', error);
        } finally {
            setPaymentInProgress(false); // Réinitialiser l'état de la demande de paiement
        }
    };


    return (
        <div className='subscription'>
            <NavBar />

            <div className='subscription-content-layout subscription-levels'>
                <img src={levels} alt="delivery-img" />
            </div>
            <div className='subscription-content-layout interne-subscription'>
                <h3>Détails de la commande</h3>
                <div className='img-product'>
                    <img src={`${config.img_url}/${product.productImage}`} alt='img-product' />
                </div>

                <div className='subscription-options'>

                    <div className='subscription-options-details'>
                        <div>
                            <h3> {product.subscriptionName} </h3>
                            <h3> FCFA {(product.subscriptionPrice / product.subscriptionQuantity)} </h3>
                        </div>

                        <div className='counter'>
                            <button onClick={decrement} className='counter-params'>
                                -
                            </button>
                            <button className='counter-params' id='quantity'>
                                {quantity}
                            </button>
                            <button onClick={increment} className='counter-params'>
                                +
                            </button>
                        </div>
                    </div>

                </div>

                <hr />

                <div className='subscription-options-details sub-total'>
                    <p>Sub Total </p>
                    <h3> {(product.subscriptionPrice / product.subscriptionQuantity) * quantity} FCFA </h3>
                </div>

                <div className='subscription-options-details frais-payment'>
                    <p>Frais Paiment </p>
                    <h3> 1%</h3>
                </div>

                <div className='subscription-options-details sub-total'>
                    <p> Total </p>
                    <h3> {((product.subscriptionPrice / product.subscriptionQuantity) * quantity) + (((product.subscriptionPrice / product.subscriptionQuantity) * quantity) / 100)} FCFA </h3>
                </div>

                <button onClick={handleSubscriptionRequest} className='validate-subscription-btn' id='btn-payer' disabled={paymentInProgress}>
                    {paymentInProgress ? 'Traitement en cours...' : 'Payer'}
                </button>

            </div>
            <div className='footer'>
                <Footer />
            </div>
        </div>
    );
}

export default Renewal;
