import React from 'react'
import CustomListBlog from '../Layouts/CustomListBlog';
import Footer from '../Layouts/Footer';
import NavBar from '../Layouts/NavBar';
import "../Assets/Styles/Blog.css"
// import bannerBlog from "../Assets/Imgs/Banner Blog.png"


function Blog() {
  return (
    <div className='blog'>
      <NavBar />
      <div className='blog-banner'>
        <h1> +100 Services Disponibles <br/> Pour Le Co-Abonnement</h1>
        <p>Découvrez une variété passionnante d'offres avec nos abonnements, cartes-cadeaux, jeux et plus encore. Que vous recherchiez le cadeau parfait</p>
      </div>
      <div className='list-blog'>
        <CustomListBlog />
      </div>
      <Footer />
    </div>
  )
}

export default Blog