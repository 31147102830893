import React from 'react'
import NavBar from '../Layouts/NavBar'
import ProductsCategory from '../Components/Products/ProductsCategory'

function Category() {
  return (
    <div>
        <NavBar />
        <ProductsCategory />
    </div>
  )
}

export default Category