import { useState } from "react";
import Instance from "../../Services/Instance.js";
import config from "../../Services/config.js";
// import google from "../../Assets/Imgs/icons/icon_google.png"
import home from "../../Assets/Imgs/templates/banner_2.png";
import "../../Assets/Styles/Connexion.css";
import { Link } from "react-router-dom";
import login from "../../Assets/Imgs/logoLogin.png";

const ForgotPassword = () => {
    const [data, setData] = useState({ email: '' });
    const [error, setError] = useState('');
    const [resReq, setResReq] = useState('');

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const url = `${config.api_url}/link-reset-password`;
            const { data: response } = await Instance.post(url, data);

            if (response) {
                const message = response.message
                setResReq(message)
            }
        } catch (error) {
            console.error('Erreur lors de la connexion:', error);
            setError(error);
        }
    };
    return (
        <div class="container-login">
            <div class="left-section-login">
                <form onSubmit={handleSubmit}>
                    <div>
                        <h1>Mot de Passe Oublier? </h1>
                        <div>
                            <p>
                                Veuillez saisir votre adresse e-mail et nous vous enverrons un mot de passe à usage unique (OTP)
                            </p>
                        </div>
                    </div>
                    <div className="parent-form-input">
                        <label htmlFor="email">Adresse Email</label>
                        <input
                            className="input"
                            type="email"
                            name="email"
                            id="email"
                            value={data.email}
                            onChange={handleChange}
                            required
                            placeholder="Entrer Votre Adresse Email"
                        />
                    </div>

                    <Link onClick={handleSubmit} class="btn" id="btn-resetPassword">
                        Réinitialiser le mot de passe
                    </Link>
                    {resReq && <p> {resReq} </p>}

                </form>
                <div>
                    {error && <h6 style={{ color: 'red' }}>{error.response.data.message}</h6>}
                </div>
            </div>

            <div class="right-section-login">
                <div class="image-container">
                    <img src={login} alt="Senpremium Design" />
                </div>
                <h2>+100 Services Disponibles</h2>
                <p id="para-login">
                    Rejoignez la meilleure application d'abonnements et profitez pleinement de vos services préférés en partageant ou rejoignant un service.
                    Spotify, Netflix, Amazon, Scribd, Appel Music, Mircosoft 365, Udemy, Iptv, etc...
                </p>
            </div>
        </div>
    );
};

export default ForgotPassword;
