import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../Layouts/NavBar';
import config from "../Services/config.js";
import Instance from '../Services/Instance';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import Footer from "../Layouts/Footer.js";
import "../Assets/Styles/Subscriptions.css";
import delivery from "../Assets/Imgs/icons/icon-fast-delivery.svg";
import freeMoney from "../Assets/Imgs/icons/freemoney.PNG";
import wave from "../Assets/Imgs/icons/wave.PNG";
import masterCard from "../Assets/Imgs/icons/mastercard.PNG";
import visa from "../Assets/Imgs/icons/visa.PNG";
import om from "../Assets/Imgs/icons/orangemoney.PNG";
import mtn from "../Assets/Imgs/icons/mtn.PNG";
import moov from "../Assets/Imgs/icons/moovmoney.PNG";
import { decryptData } from '../Services/EncryptData.js';
import attentionIcon from "../Assets/Imgs/icons/infos avertissement.svg"
import iconPhonePcActive from "../Assets/Imgs/icons/btn_phonePc_active.png"
import iconPhonePcEnable from "../Assets/Imgs/icons/btn_phonePc_enable.png"
import iconSmartTVEnable from "../Assets/Imgs/icons/btn_smarttv_enable.png"
import iconSmartTVActive from "../Assets/Imgs/icons/btn_smarttv_active.png"
import { DataContext } from '../Services/DataContext.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import CustomListLayout from '../Layouts/CustomListLayout.js';
import listElementsScrolling from '../Components/Home/ListElementsScrolling.js';
import moreFrequently from "../Assets/Imgs/icons/mostFrequently.svg"
import ShowCart from '../Components/Cart/ShowCart.js';


function Subscription() {
    const [error, setError] = useState("");
    const [product, setProduct] = useState([]);
    const [faqs, setFaqs] = useState([]);
    const [isInWishlist, setIsInWishlist] = useState(false);
    const [wishlist, setWishlist] = useState([]);

    const { video, music, services, securite, design, carteCadeauEtJeux,gaming, education, logiciel } = useContext(DataContext);

    const { id } = useParams();
    const [dataUser__, setDataUser__] = useState(null);

    useEffect(() => {
        const user__ = localStorage.getItem("user");
        if (user__) {
            const decryptedUser = decryptData(user__);
            setDataUser__(decryptedUser);
        }
    }, []);

    const [optionNetflix, setOptionNetflix] = useState(false)
    library.add(faHeart);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(`${config.api_url}/show-product/${id}`);
                setProduct(result.data.product);
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };
        window.scrollTo(0, 0);
        fetchData();
        optionPhonePc()
    }, [id]);


    const optionSmartTV = () => {
        setOptionNetflix(true)
        localStorage.setItem("smartTvPrice", product.productPrice2)
        localStorage.setItem("netflixType", "Smart TV")

    }
    const optionPhonePc = () => {
        setOptionNetflix(false)
        localStorage.removeItem("smartTvPrice")
        localStorage.removeItem("netflixType")
    }


    useEffect(() => {
        const defaultFaqs = product.faq || [];
        if (defaultFaqs.length > 0) {
            // Initialize the first FAQ with statusFaq: true
            defaultFaqs[0].statusFaq = true;
        }
        setFaqs(defaultFaqs.map(faq => ({ ...faq, statusFaq: faq.statusFaq || false })));
    }, [product]);

    const toggleFaq = (index) => {
        setFaqs(prevFaqs => prevFaqs.map((faq, i) => {
            if (i === index) {
                return { ...faq, statusFaq: !faq.statusFaq };
            } else {
                return faq;
            }
        }));
    };

    //Add to cart option 

    const { addToCart, showCart, setShowCart, cartItems } = useContext(DataContext);


    useEffect(() => {
        const fetchWishlist = async () => {
            try {
                if (dataUser__) {
                    const result = await Instance.get(`${config.api_url}/wish-list/${dataUser__._id}`);
                    setWishlist(result.data.wishlist);

                }
            } catch (error) {
                setError("Erreur lors de la récupération de la liste de souhaits.");
                // console.log(error);
            }
        };

        fetchWishlist();
    }, [dataUser__]);




    useEffect(() => {
        if (wishlist && product && dataUser__) {
            const inWishlist = wishlist.filter((item) => {
                return item.product._id === product._id;
            });

            if (inWishlist.length !== 0) {
                setIsInWishlist(true)
            }
        }
    }, [wishlist]);

    const toggleWishlist = async () => {
        if (!dataUser__) {
            return alert('Vous devez vous connectez pour aimer ce produit')
        }
        if (isInWishlist) {
            return alert('Ce produit a été déjà ajouter à votre liste de Souhaits')
        }
        try {
            const result = await Instance.post(`${config.api_url}/new-wish-product`, { userId: dataUser__._id, productId: product._id });

            if (result.status === 201) {
                setIsInWishlist(true)
            }
        } catch (error) {
            setError("Erreur lors de l'ajout à la liste de souhaits.");
        }
    }
    // Fonction pour mélanger le tableau de manière aléatoire
    function shuffleArray(array) {
        return array.sort(() => Math.random() - 0.5);
    }

    //similar Products        
    const shuffledListElements = (product) => {

        let listElementsScrolling = [];

        // Vérifier la catégorie du produit et récupérer les éléments correspondants
        switch (product.productCategory) {
            case 'Streaming Video':
                listElementsScrolling = video;
                break;
            case 'Music':
                listElementsScrolling = music;
                break;
            case 'Services':
                listElementsScrolling = services;
                break;
            case 'Securite':
                listElementsScrolling = securite;
                break;
            case 'Design':
                listElementsScrolling = design;
                break;
            case 'Cartes Cadeaux et Jeux Video':
                listElementsScrolling = carteCadeauEtJeux;
                break;
            case 'Education':
                listElementsScrolling = education;
                break;
            case 'Logiciel':
                listElementsScrolling = logiciel;
                break;
                case 'Gaming':
                listElementsScrolling = gaming;
                break;
            default:
            // console.warn('Catégorie inconnue');
        }

        // Si des éléments sont trouvés, on les mélange
        if (listElementsScrolling.length > 0) {
            return shuffleArray(listElementsScrolling);
        } else {
            return []; // Retourne une liste vide si aucune correspondance n'a été trouvée
        }
    };



    // Mélanger les éléments de listElementsScrolling
    const similarProducts = shuffledListElements(product);

    //Show cart
    const handleShowCart = () => {
        setShowCart(true)
    }
    // if (cartItems.length === 0) {
    //     setShowCart(false)
    // }
// console.log(product);

    return (
        <div className='subscription '>
            <NavBar />
            {showCart && <div className='' id='cart-show'>
                <ShowCart />
            </div>}
            <div className='subscription-content-layout subscription-delivery ' id={`${showCart ? "bg-subs-show-cart" : ""}`}>
                <img src={delivery} alt="delivery-img" />
                <p>Abonnement ou Code livré <b id='payment'>instantanément</b> sur votre écran et par email après le paiement </p>
            </div>
            <div className='sous-navbar' id={`${showCart ? "bg-subs-show-cart sous-navbar-sub " : "sous-navbar-sub "}`}>
                <Link to="/" className='link-sous-navbar'> Home </Link>
                <span>/</span>
                <Link to="/products" className='link-sous-navbar'> Abonnements </Link>
                <span>/</span>
                <Link to={``} className="link-sous-navbar" id='activeLink'> Détails Abonnements</Link>
            </div>
            <div className='subscription-content-layout subscription-container ' id={`${showCart ? "bg-subs-show-cart" : ""}`} >

                <div className='subscription-content-details'>
                    <div className='content-layout content-left'>
                        <div className='content-wish-icon'>
                            <FontAwesomeIcon icon={faHeart}
                                className={`wish-icon ${isInWishlist ? 'wish-icon-active' : ''}`}
                                onClick={toggleWishlist}
                            />
                        </div>
                        <div className='img-product'>
                            <img src={`${config.img_url}/${product.productImage}`} alt="img-product" />
                        </div>
                    </div>
                    <div className='content-layout content-right'>
                        <h2>{product.productName}</h2>
                        <div>
                            {!optionNetflix && <h2 className='style-price'>{product.productPrice} FCFA</h2>}
                            {optionNetflix && <h2 className='style-price'>{product.productPrice2} FCFA</h2>}

                        </div>
                        <div className='content-description-others'>
                            <div className='content-right-details'>
                                <div className='content-right-details-child'>
                                    <h3>Catégorie</h3>
                                    <p className='content-description'>{product.productCategory}</p>
                                </div>
                                {product.productType &&
                                    <div className='content-right-details-child'>
                                        <h3>Type</h3>
                                        <p className='content-description'>{product.productType}</p>
                                    </div>
                                }
                            </div>
                            <div>
                                <h3 id='content-description'>Description</h3>
                                <p className='content-description'>{product.productDescription}</p>
                            </div>
                        </div>

                    </div>

                </div>

                <div className='content-option-phone-pc'>
                    <div className={product.productName
                        && product.productName.toLocaleLowerCase().includes("netflix")
                        && !product.productName.includes("NETFLIX Pack Family")
                        && !product.productName.includes("Netflix Duo")
                        ? 'type-abonnement' : "type-abonnemen-enable"}>
                        {product.productName && product.productName.toLocaleLowerCase().includes("netflix")
                            && !product.productName.includes("NETFLIX Pack Family")
                            && !product.productName.includes("Netflix Duo")
                            && (
                                <div>
                                    <h3>Type d'abonnement Netflix</h3>
                                    <div className='option-type-abonnement'>
                                        <img src={optionNetflix ? iconPhonePcEnable : iconPhonePcActive} alt="phone-pc" onClick={optionPhonePc} />
                                        <img src={optionNetflix ? iconSmartTVActive : iconSmartTVEnable} alt="smartTV" onClick={optionSmartTV} />
                                    </div>
                                </div>
                            )}


                    </div>

                </div>
                <div className='validate-subscription-infos'>
                    <Link to={""} onClick={() => { addToCart(product._id); handleShowCart() }} className='validate-subscription-btn' id='btn-buy-now'>Ajouter au panier</Link>
                    <Link to={dataUser__ ? `/new-subscription/${product._id}` : `/unknown-new-subscription/${product._id}`} className='validate-subscription-btn'>Valider</Link>
                </div>
                {product.productName
                    && product.productName.toLocaleLowerCase().includes("netflix")
                    && !product.productName.includes("NETFLIX Pack Family")
                    && !product.productName.includes("Netflix Duo")
                    && <div className='attention'>
                        <img src={attentionIcon} alt="attention" />
                        {!optionNetflix && <p>  Cette offre est valable pour les <span className='option-netflix-active'>téléphones ou PC</span>. Si vous avez une Smart TV, veuillez choisir l'offre NETFLIX Smart TV </p>}
                        {optionNetflix && <p> Cette offre est valable pour les <span className='option-netflix-active'>Smart TV</span>. Si vous avez un téléphones ou PC, veuillez choisir l'offre NETFLIX Phone/Pc </p>}
                    </div>}

                {product.productAttention && (product.productAttention !== "" && product.productAttention !== "undefined") &&  <div className='attention'>
                    <img src={attentionIcon} alt="attention" />
                    <p className='avertissement'> {product.productAttention} </p>
                </div>}
            </div>

            <div className='subscription-content-layout subscription-container' id={`${showCart ? "bg-subs-show-cart" : ""}`}>
                <h1 className='faq-title'>FAQ</h1>
                <div className='faq-content'>
                    {faqs.map((faq, index) => (
                        <div key={index}>
                            <div className='faq-item'>
                                <p>{faq.question}</p>
                                {faq.statusFaq ? (
                                    <h1 onClick={() => toggleFaq(index)} className='chevron arrow-faq'>⌵</h1>
                                ) : (
                                    <h1 onClick={() => toggleFaq(index)} className='arrow-faq'>⌵</h1>
                                )}
                            </div>
                            {faq.statusFaq && (
                                <p className='faq-description'>{faq.answer}</p>
                            )}
                            <hr />
                        </div>
                    ))}
                </div>
            </div>
            <div className='subscription-content-layout subscription-container content-payment' id={`${showCart ? "bg-subs-show-cart" : ""}`}>
                <h2 className='title-payment'> <span id='payment'>Paiement</span> <span id='secure'>Sécurisé</span> </h2>
                <div className='payment-secure-img'>
                    <img src={wave} alt="img-payment" />
                    <img src={freeMoney} alt="img-payment" />
                    <img src={om} alt="img-payment" />
                    <img src={masterCard} alt="img-payment" />
                    <img src={visa} alt="img-payment" />
                    <img src={mtn} alt="img-payment" />
                    <img src={moov} alt="img-payment" />
                </div>
            </div>
            <div className='subscription-content-layout subscription-container' id={`${showCart ? "bg-subs-show-cart" : ""}`} >
                <CustomListLayout
                    listItems={similarProducts}
                    typeName="Produit Similaires"
                    iconType={moreFrequently}
                />
            </div>
            <Footer />
        </div >
    )
}

export default Subscription;
