import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DataContext } from '../../Services/DataContext';
import CustomProductsCategories from './CustomProductsCategories';
import listElementsScrolling from '../Home/ListElementsScrolling';
import psn from "../../Assets/Imgs/icons/Senpreimium logo icone/PSN.png";
import iTunes from "../../Assets/Imgs/icons/Senpreimium logo icone/iTunes.png";
import Roblox from "../../Assets/Imgs/icons/Senpreimium logo icone/Roblox.png";
import Xbox from "../../Assets/Imgs/icons/Senpreimium logo icone/Xbox.png";
import Fortnite from "../../Assets/Imgs/icons/Senpreimium logo icone/Fortnite.png";
import PUBG from "../../Assets/Imgs/icons/Senpreimium logo icone/PUBG.png";
import Netflix from "../../Assets/Imgs/icons/Senpreimium logo icone/Netflix.png";
import Spotify from "../../Assets/Imgs/icons/Senpreimium logo icone/Spotify.png";
import DisneyPlus from "../../Assets/Imgs/icons/Senpreimium logo icone/Disney Plus.png";
import Steam from "../../Assets/Imgs/icons/Senpreimium logo icone/Steam.png";
import Deezer from "../../Assets/Imgs/icons/Senpreimium logo icone/Deezer.png";
import PrimeVideo from "../../Assets/Imgs/icons/Senpreimium logo icone/Prime Video.png";
import iconGaming from "../../Assets/Imgs/icons/icons8-game-controller-50.png"
import iconCArteCadeau from "../../Assets/Imgs/icons/icon-carte-cadeau.svg";
import iconEducation from "../../Assets/Imgs/icons/icon-education2.svg";
import iconSecurity from "../../Assets/Imgs/icons/icon-security2.svg";
import iconLogiciel from "../../Assets/Imgs/icons/icon-app2.svg";
import iconVideo from "../../Assets/Imgs/icons/icon-tv2.svg";
import iconDesign from "../../Assets/Imgs/icons/icon-design.svg";
import moreFrequently from "../../Assets/Imgs/icons/mostFrequently.svg"
import iconMusic from "../../Assets/Imgs/icons/icon-music2.svg";
import allProducts from "../../Assets/Imgs/icons/allProducts.svg"




function ProductsCategory() {
    const { categoryPath } = useParams();
    const location = useLocation();
    const { products, video, music, services, securite, gaming, design, carteCadeauEtJeux, education, logiciel } = useContext(DataContext);

    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const categories = [
        { name: 'Les plus Populaires', path: 'Les plus Populaires', items: listElementsScrolling, srcImg: moreFrequently, subCategories: [] },
        { name: 'Tous les Produits', path: 'Tous les produits', items: products, srcImg: allProducts, subCategories: [] },
        {
            name: 'Streaming Vidéo', path: 'Streaming Video', items: video, srcImg: iconVideo,
            subCategories: [
                { name: 'Netflix', path: 'Netflix', srcImg: Netflix },
                { name: 'Prime Video', path: 'Prime Video', srcImg: PrimeVideo },
                { name: 'Disney Plus', path: 'Disney Plus', srcImg: DisneyPlus },
                { name: 'Crunchyroll', path: 'Crunchyroll', srcImg: iconVideo }, // Pas d'image spécifique, donc on utilise celle de la catégorie
                { name: 'Autre', path: 'Autre', srcImg: iconVideo } // Utilisation de l'image par défaut de la catégorie
            ]
        },
        {
            name: 'Jeux', path: 'Gaming', items: gaming.concat(carteCadeauEtJeux), srcImg: iconGaming,
            subCategories: [
                { name: 'PlayStation', path: 'PSN', srcImg: psn },
                { name: 'Fortnite', path: 'Fortnite', srcImg: Fortnite },
                { name: 'Call of Duty', path: 'Call of Duty', srcImg: iconGaming },
                { name: 'Roblox', path: 'Roblox', srcImg: Roblox },
                { name: 'Valorant Points', path: 'Valorant Points', srcImg: iconGaming },
                { name: 'Xbox', path: 'Xbox', srcImg: Xbox },
                { name: 'PUBG', path: 'PUBG', srcImg: PUBG },
                { name: 'Autre', path: 'Autre', srcImg: iconGaming } 
            ]
        },
        {
            name: 'Carte Cadeau Recharge', path: 'Cartes Cadeaux', items: gaming.concat(carteCadeauEtJeux), srcImg: iconCArteCadeau,
            subCategories: [
                { name: 'PSN', path: 'PSN', srcImg: psn },
                { name: 'Roblox', path: 'Roblox', srcImg: Roblox },
                { name: 'Fortnite', path: 'Fortnite', srcImg: Fortnite },
                { name: 'Xbox', path: 'Xbox', srcImg: Xbox },
                { name: 'Steam', path: 'Steam', srcImg: Steam },
                { name: 'iTunes France', path: 'iTunes France', srcImg: iTunes },
                { name: 'iTunes USA', path: 'iTunes USA', srcImg: iTunes }, 
                { name: 'Playstore', path: 'Playstore', srcImg: iconCArteCadeau }, 
                { name: 'Autre', path: 'Autre', srcImg: iconGaming } 

            ]
        },
        {
            name: 'Musique', path: 'Musique', items: music, srcImg: iconMusic,
            subCategories: [
                { name: 'Spotify', path: 'Spotify', srcImg: Spotify },
                { name: 'Deezer', path: 'Deezer', srcImg: Deezer },
                { name: 'Apple Music', path: 'Apple Music', srcImg: iconMusic }, // Utilisation de l'image de la catégorie
                { name: 'Autre', path: 'Autre', srcImg: iconMusic } // Image par défaut
            ]
        },
        { name: 'Design', path: 'Design', items: design, srcImg: iconDesign, subCategories: [] },
        { name: 'Sécurité', path: 'Securite', items: securite, srcImg: iconSecurity, subCategories: [] },
        { name: 'Services', path: 'Services', items: services, srcImg: iconCArteCadeau, subCategories: [] },
        { name: 'Éducation', path: 'Education', items: education, srcImg: iconEducation, subCategories: [] },
        { name: 'Logiciel', path: 'Logiciel', items: logiciel, srcImg: iconLogiciel, subCategories: [] }
    ];


    useEffect(() => {
        const selectedCategory = categories.find(cat => cat.path === categoryPath);
        const items = selectedCategory ? selectedCategory.items : products;
        setSelectedItems(items);
        setSelectedSubCategory('');

        if (categoryPath && (categoryPath !== 'Netflix' && categoryPath !== 'videoprime')) {
            window.scroll(0, 0);
        }
    }, [categoryPath, location.pathname, products]);

    const filterItems = (items, searchTerm) => {
        if (searchTerm) {
            return items.filter(item => item.productName?.toLowerCase().includes(searchTerm.toLowerCase()));
        }
        return items;
    };

    const cleanString = (str) => str.toLowerCase().replace(/\s+/g, ' ').trim();

    const handleSubCategoryClick = (sub) => {
        setSelectedSubCategory(sub.path);

        // Filtrage en fonction de la sous-catégorie sélectionnée
        const selectedCategory = categories.find(cat => cat.path === categoryPath);
        const items = selectedCategory ? selectedCategory.items : products;

        const filteredItems = sub.path !== 'Autre'
            ?
            items.filter(item => {
                const productName = cleanString(item.productName || "");
                const subPathWords = cleanString(sub.path).split(" ");
                // Vérifier si tous les mots de sub.path sont inclus dans item.productName
                return subPathWords.every(word => productName.includes(word));
            })

            : items; // Afficher tous les éléments pour "Autre"

        // Mettre à jour les éléments sélectionnés
        setSelectedItems(filteredItems);
    };



    const filteredItems = filterItems(selectedItems, searchTerm);

    return (
        <div className='products-category '>
            <div className='sous-navbar sous-navbar-products-category'>
                <Link to="/" className='link-sous-navbar'>Home</Link>
                <span>/</span>
                <Link to="/products" className='link-sous-navbar'>Abonnements</Link>
                <span>/</span>
                <Link to={`/products/products-category/${categoryPath}`} className="link-sous-navbar" id='activeLink'>{categoryPath}</Link>
            </div>

            <div className='dropdown-categories-products'>
                <div className='categories-content'>
                    {/* Menu déroulant des catégories */}
                    <select
                        className='category-dropdown'
                        onChange={(e) => {
                            const selectedCategoryPath = e.target.value;
                            if (selectedCategoryPath) {
                                window.location.href = `/products/products-category/${selectedCategoryPath}`;
                            }
                        }}
                    >
                        <option value="" id='option-dropdown-categories'>Sélectionnez une catégorie</option>
                        {categories.map((category, index) => (
                            <option key={index} value={category.path}>{category.name}</option>
                        ))}
                    </select>

                    {/* Affichage des sous-catégories sur une nouvelle ligne */}
                    <div className='sub-category-row'>
                        {categories.map((category, index) => (
                            category.path === categoryPath && category.subCategories.length > 0 && (
                                <div key={index} className='child-dropdown-bar'>
                                    {category.subCategories.map((sub, subIndex) => (
                                        <div id='mini-nav-categories-products'
                                            className={selectedSubCategory === sub.path ? 'subActive' : ''}
                                            onClick={() => handleSubCategoryClick(sub)}
                                        >
                                            <img src={sub.srcImg} alt={category.name} />

                                            <button
                                                key={subIndex}
                                                className='btn-mininav-dropdown'
                                            >
                                                {sub.name}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )
                        ))}
                    </div>

                </div>
            </div>

            <div className='categories-content'>
                <div className='side-bar-categories'>
                    {categories.map((category, index) => (
                        <div
                            className='child-side-bar'
                            key={index}
                            id={location.pathname.replace(/%20/g, ' ').includes(category.path) ? 'activeLinkSideBAr' : ''}
                        >
                            <Link
                                to={`/products/products-category/${category.path}`}
                                className="link-side-bar-categories"
                            >
                                <img src={category.srcImg} alt={category.name} />
                                {category.name}
                            </Link>

                            {category.path === categoryPath && category.subCategories.length > 0 && (
                                <div className="sub-categories">
                                    {category.subCategories.map((sub, subIndex) => (
                                        <button
                                            key={subIndex}
                                            className={selectedSubCategory === sub.path ? 'subActive' : ''}
                                            onClick={() => handleSubCategoryClick(sub)}
                                        >
                                            {sub.name}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className='category-products'>
                    {/* Barre de recherche */}
                    <input
                        type="text"
                        placeholder="Rechercher des produits..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-bar-product-cat"
                    />



                    <CustomProductsCategories items={filteredItems} />
                </div>
            </div>
        </div>
    );
}

export default ProductsCategory;
