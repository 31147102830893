import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import NavBar from '../../Layouts/NavBar'
import { DataContext } from '../../Services/DataContext';


function FailedSubscription() {
  const {  clearItem } = useContext(DataContext);
  const handleClose = () => {
    clearItem();
  };

  return (
    <div >
      <NavBar />
      <div className='failed-subscription'>
        <h1> Votre Abonnement ou Achat a été <span id='how' >Annulé</span> !!! </h1>
        <Link to="/" onClick={() => { handleClose() }}  className='btn-failed'> Fermer  </Link>
      </div>

    </div>
  )
}

export default FailedSubscription