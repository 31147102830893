import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from 'react-router-dom';
import Instance, { googleAuth } from "../../Services/Instance";
import config from "../../Services/config";
import { encryptData } from "../../Services/EncryptData";
import iconGoogle from "../../Assets/Imgs/icons/icon-google.svg"


const LoginGoogle = (props) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    const responseGoogle = async (authResult) => {
        try {
            if (authResult["code"]) {
                const result = await googleAuth(authResult.code);
                const { email, name } = result.data.user;
                const token = result.data.token;

                // Enregistrer le token dans le localStorage
                localStorage.setItem("token", token);

                //  vérifier que l'utilisateur existe dans la BDD :
                const userResponse = await Instance.get(`${config.api_url}/show-user-by-email/${email}`);
                const userFromDB = userResponse.data.user;

                // Utiliser directement les données utilisateur récupérées
                const encryptUser = encryptData(userFromDB);
                localStorage.setItem("user", encryptUser);

                // Redirection vers la page d'accueil
                window.location = "/";

            } else {
                console.log(authResult);
                throw new Error(authResult);
            }
        } catch (e) {
            console.log('Error while Google Login...', e);
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: responseGoogle,
        onError: responseGoogle,
        flow: "auth-code",
    });

    return (
        <div className="google-login">
            <button  onClick={googleLogin}>
			<img src={iconGoogle} alt="icon-google" /> Se Connecter avec google
            </button>
        </div>
    );
};

export default LoginGoogle;
