import React, { useEffect } from "react";
import Banner from "../Components/Home/Banner";
import HowItWorks from "../Components/Home/HowItWorks";
import AboutUs from "../Components/Home/AboutUs";
import Footer from "../Layouts/Footer";
import "../Assets/Styles/Home.css"
import SubShare from "../Components/Home/SubShare";
import NavBar from "../Layouts/NavBar";
import { decryptData } from "../Services/EncryptData";
import CustomListLayout from "../Layouts/CustomListLayout";
import listElementsScrolling from '../Components/Home/ListElementsScrolling';
import moreFrequently from "../Assets/Imgs/icons/mostFrequently.svg"
import HowToWork2 from "../Components/Home/HowToWork2";


function Home() {

  const _user = localStorage.getItem('user');
  const user = _user === null ? null : decryptData(_user)

  // console.log(user);
  user ?
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "acvunhpb",
      name: `${user.firstName} ${user.lastName}`, // Nom complet
      email: user.email, // l’e-mail de votre utilisateur
      created_at: user.createdAt, // Date d’inscription en tant qu’horodatage Unix
    })
    :
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "acvunhpb",
      name: "Boss", // Nom complet
    })
  window.Intercom("update");
  window.scrollTo(0, 0);

  // Vérifie l'expiration du token lors du chargement initial du composant
  useEffect(() => {
    checkTokenExpiration();
  }, []);

  // Fonction pour vérifier l'expiration du token
  const checkTokenExpiration = () => {
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (tokenExpiration) {
      const expirationTime = parseInt(tokenExpiration);
      const currentTime = new Date().getTime();
      if (currentTime > expirationTime) {
        // Supprimer le token et déconnecter l'utilisateur
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        localStorage.removeItem('user');
      }
    }
  };

  // Fonction pour mélanger le tableau de manière aléatoire
  function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
  }

  // Mélanger les éléments de listElementsScrolling
  const shuffledListElements = shuffleArray(listElementsScrolling);


  return (
    <div className="home">
      <NavBar />
      <Banner />
      <div className="more-freq">
        <CustomListLayout
          listItems={shuffledListElements}
          typeName="Pour Vous"
          iconType={moreFrequently}
        />
      </div>
      <SubShare />
      <HowToWork2 />
      <AboutUs />
      <Footer />
    </div>
  )
}

export default Home