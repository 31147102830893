import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../Assets/Imgs/icons/logo-senpremium2.svg';
import logoMobile from '../Assets/Imgs/icons/logo senpremium.png';
import { DataContext } from '../Services/DataContext';
import "../Assets/Styles/MainLayout.css";
import sousMenu from "../Assets/Imgs/icons/sous-menu.svg";
import { decryptData } from '../Services/EncryptData';
import iconConnection from "../Assets/Imgs/icons/icon-connexion.svg";
import iconConnectionMobile from "../Assets/Imgs/icons/icon-connection-mobile.svg";
import cart from "../Assets/Imgs/icons/mdi_cart.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faUser } from '@fortawesome/free-solid-svg-icons';


function NavBar() {
  const { toggleBooleanValue, cartItems, setShowCart } = useContext(DataContext);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();  // Pour déterminer l'URL actuelle
  const user = localStorage.getItem("user");
  const dataUser = user === null ? null : decryptData(user);
  const [err, setErr] = useState(false)
  const [loading, setLoading] = useState(true);

  const handleLogout = (e) => {
    try {
      e.preventDefault();
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('tokenExpiration');
      toggleBooleanValue();
      window.location.href = '/';
    } catch (err) {
      setErr(err)
    } finally {
      setLoading(false);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const sousMenu = document.querySelector(".sous-menu");
    const navLinks = document.querySelector(".connection-link");

    const handleClick = () => {
      navLinks.classList.toggle('mobile-menu');
    };

    sousMenu.addEventListener('click', handleClick);

    return () => {
      sousMenu.removeEventListener('click', handleClick);
    };
  }, []);

  //Show cart
  const handleShowCart = () => {
    setShowCart(false)
  }

  // Affichage en cas de chargement ou d'erreur
  // if (loading) return <div>Chargement...</div>;
  // if (err) return <div>{err}</div>;

  return (
    <div className='nav-bar'>
      <div className='nav-bar-responsive'>
        <Link to='/'>
          <img src={logo} alt='logo' className='logo' />
          <img src={logoMobile} alt='logo' className='logo-mobile' />
        </Link>
        <div className='sous-menu'>
          {
            dataUser ? (
              <Link
                to='/account'
                className={`link-nav-bar  ${location.pathname === '/user-compte' ? 'active' : ''}`}
              >
              <FontAwesomeIcon icon={faUser}  className='icon-connection-mobile icon-signin-mobile' />
              </Link>
            ) :
              (
                <Link
                  to='/signin'
                  className={`link-nav-bar  ${location.pathname === '/user-compte' ? 'active' : ''}`}
                >
                  <FontAwesomeIcon icon={faUser} className='icon-connection-mobile icon-signin-mobile' style={{color : "#f4922a"}} />
                </Link>
              )
          }

          <Link
            to='/cart'
            id='link-cart'
            className={`link-nav-bar  ${location.pathname === '/cart' ? 'active' : ''}`}
          >
            <div id='length-items-cart'>
              <span className='items-cart' > {cartItems.length} </span>
            </div>
            <img src={cart} className='icon-connection-mobile' alt="icon-conexion" />
          </Link>
          <img src={sousMenu} alt="sous-menu" className='' />
        </div>
      </div>



      <div className='connection-link'>
        <Link
          to='/'
          className={`link-nav-bar ${location.pathname === '/' ? 'active' : ''}`}
        >
          ACCUEIL
        </Link>
        <Link
          to='/products'
          className={`link-nav-bar ${((location.pathname.includes("/products")) || (location.pathname.includes("/subscription")) || (location.pathname.includes("/unknown-new-subscription"))) ? 'active' : ''}`}
        >
          ABONNEMENTS
        </Link>
        <Link
          to='/blog'
          className={`link-nav-bar ${location.pathname === '/blog' || (location.pathname.includes("/blog")) ? 'active' : ''}`}
        >
          BLOG
        </Link>
      </div>


      <div className='user-compte'>
        <Link
          to='/cart'
          className={`link-nav-bar nav-cart ${location.pathname === '/cart' ? 'active' : ''}`}
        // onClick={handleShowCart(true)}
        >
          <div id='length-items-cart'>
            <span className='items-cart' > {cartItems.length} </span>
          </div>
          <img src={cart} alt="icon-conexion" />

        </Link>
        <div className='connection'>
          {dataUser ? (
            <div className='dropdown'>
              <Link to="/account" className=' btn-connection' >
                <img src={iconConnection} alt="icon-conexion" />
                <p>Mon compte</p>
              </Link>
              <div className='dropdown-content'>
                <hr />
                <Link to='/user-subscriptions' className='dropdown-item'>
                  Mes Abonnements
                </Link>
                <hr />
                <Link to='/user-shares' className='dropdown-item'>
                  Partager un abonnement
                </Link>
                <hr />
                <Link to={`/profile/${dataUser._id}`} className='dropdown-item'>
                  Profil
                </Link>
                <hr />
                <Link onClick={handleLogout} className='dropdown-item'>
                  Déconnexion
                </Link>
              </div>
            </div>
          ) : (
            <Link to='/signin' className='general-btn-link link-nav-bar btn-connection'>
              <img src={iconConnection} alt="icon-conexion" />
              Connexion
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default NavBar;
