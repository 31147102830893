import React, { useEffect, useState } from 'react';
import NavBar from '../../Layouts/NavBar';
import config from '../../Services/config.js';
import Instance from '../../Services/Instance';
import { useParams } from 'react-router-dom';
import '../../Assets/Styles/Subscriptions.css';
import levels from "../../Assets/Imgs/icons/icon-levels-subscriptions.svg"
import Footer from '../../Layouts/Footer';
import { v4 as uuidv4 } from 'uuid';
import { decryptData } from '../../Services/EncryptData.js';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faTicket } from '@fortawesome/free-solid-svg-icons';


function NewSubscription() {
    // Déclaration des états
    const [error, setError] = useState('');
    const [product, setProduct] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(true);
    const [paymentInProgress, setPaymentInProgress] = useState(false); // État pour suivre l'état de la demande de paiement
    const [licenseToReserve, setLicenseToReserve] = useState({
        statusLicenseToReserve: false,
        subscriptionEmailKey: "",
        subscriptionPWKey: "",
    })


    // Récupération de l'identifiant du produit depuis les paramètres de l'URL
    const { id } = useParams();
    //Recuperation de l'id de la commande 

    // Récupération de l'utilisateur depuis le stockage local
    const user__ = localStorage.getItem("user")
    const user = user__ === null ? null : decryptData(user__)


    const { subscriptionEmailKey, subscriptionPWKey, statusLicenseToReserve } = licenseToReserve;

    //Add a Promo
    const [codePromo, setCodePromo] = useState(false)
    const [pointsWin, setPointsWin] = useState(false)

    const [applyCodePromo, setApplyCodePromo] = useState("");
    const [pointsApplied, setPointsApplied] = useState(0);

    const [discountedPrice, setDiscountedPrice] = useState(0);
    const [discountedPricePoints, setDiscountedPricePoints] = useState(0);

    const [promoApplied, setPromoApplied] = useState(false);

    //Add a code promo
    const ActifDiscount = (type) => {
        if (type === "promo") {
            setCodePromo((prevState) => !prevState);
        } else if (type === "points") {
            setPointsWin((prevState) => !prevState);
        }
    };

    const handleApplyPromoOrPoints = (e, type) => {
        if (type === "promo") {
            const newPromoValue = e.target.value;
            if (applyCodePromo !== newPromoValue) {
                setApplyCodePromo(newPromoValue); // Mise à jour seulement si la valeur est différente
            }
        } else if (type === "points") {
            const newPointsValue = parseInt(e.target.value, 10); // Convertir la valeur en entier
            if (pointsApplied !== newPointsValue) {
                setPointsApplied(newPointsValue); // Mise à jour seulement si la valeur est différente
            }
        }
    };

    // Vérification et application du code promo
    const applyDiscount = () => {

        if (applyCodePromo === product.promo.codePromo) {
            const discount = (product.productPrice * product.promo.pourcentagePromo) / 100;
            setDiscountedPrice(discount);
            setPromoApplied(true);
            alert("Code promo appliqué avec succès !");
        } else {
            alert("Le code promo saisi n'est pas valide.");
            setPromoApplied(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLicenseToReserve({ ...licenseToReserve, [name]: value });
    };
    const handleChangeStatus = () => { setLicenseToReserve({ ...licenseToReserve, statusLicenseToReserve: !statusLicenseToReserve }) }


    // Effet de chargement initial pour récupérer les détails du produit
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(`${config.api_url}/show-product/${id}`);
                setProduct(result.data.product);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        window.scrollTo(0, 0);
        fetchData();
    }, [id]);


    // Incrémentation de la quantité
    const increment = () => {
        setQuantity(quantity + 1);
    };

    // Décrémentation de la quantité
    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    //Recupérer le prix du smart tv 
    const smarTvPrice = localStorage.getItem('smartTvPrice');
    let netflixType = localStorage.getItem("netflixType")
    let optionSub = "";

    let priceSub = smarTvPrice ? smarTvPrice : product.productPrice;
    if (promoApplied) {
        console.log(discountedPrice);

        priceSub = priceSub - discountedPrice
    }
    if (netflixType) {
        optionSub = `- ${netflixType}`
    } else if (!netflixType && product.productName && product.productName.toLocaleLowerCase().includes("netflix") && !product.productName.includes("NETFLIX Pack Family") && !product.productName.includes("Netflix Duo")) {
        optionSub = "- Phone/Pc"
    }

    const winPoints = () => {
        return Math.ceil((quantity * priceSub) / 40)
    }

    // Appliquer les points
    const applyDiscountPoints = async () => {
        const result = await Instance.get(`${config.api_url}/show-user/${user._id}`);
        const userPoints = result.data.user.point;

        if (userPoints >= pointsApplied) {
            const discountPoints = pointsApplied;

            // Vérification pour empêcher d'appliquer à nouveau les points
            if (discountPoints > priceSub - 100) {
                const maxApply = Math.ceil((priceSub - 100) / 40);
                alert(`Le maximum de points que vous pouvez appliquer pour cette commande est de ${maxApply}`);
            } else {
                // Réinitialiser la remise avant de recalculer
                setDiscountedPricePoints(discountPoints);
                setDiscountedPrice(0); // Réinitialiser toute ancienne remise avant d'appliquer la nouvelle

                const totalDiscount = discountPoints; // Se baser seulement sur la nouvelle valeur
                setDiscountedPrice(totalDiscount);
                setPromoApplied(true);
            }
        } else {
            alert("Vous ne disposez pas de ce nombre de points");
        }
    };


    // const handleSubscriptionRequest = async (orderRequest) => {
    const handleSubscriptionRequest = async () => {
        if (paymentInProgress) return; // Si la demande de paiement est déjà en cours, ne rien faire

        try {
            const orderRequestSubscription = {
                productId: product._id,
                subscriptionQuantity: quantity,
                productTypeSmart: optionSub,
                subPoints: [winPoints(), pointsApplied],
                userId: user._id,
            }
            setPaymentInProgress(true); // Définir l'état de la demande de paiement en cours

            const subscriptionResponse = await Instance.post(`${config.api_url}/new-subscription`, [orderRequestSubscription]);
            // console.log('Subscription Response:', subscriptionResponse);
            // Vérifier si la réponse contient une URL de paiement
            if (subscriptionResponse && subscriptionResponse.data && subscriptionResponse.data.payment) {
                window.location.href = subscriptionResponse.data.payment; // Redirection vers l'URL de paiement
            } else {
                // Gérer le cas où il n'y a pas d'URL de paiement
                console.error('URL de paiement manquante dans la réponse de souscription.');
                alert('Erreur lors du paiement.'); // Afficher un message d'erreur à l'utilisateur
                window.location.reload();
            }
        } catch (error) {
            console.error('Erreur lors de la demande d\'abonnement :', error);
        } finally {
            setPaymentInProgress(false); // Réinitialiser l'état de la demande de paiement
        }
    };


    // console.log(product)
    return (
        <div className='subscription'>
            <NavBar />

            <div className='subscription-content-layout subscription-levels'>
                <img src={levels} alt="delivery-img" />
            </div>
            <div className='sous-navbar ' >
                <Link to="/" className='link-sous-navbar'> Home </Link>
                <span>/</span>
                <Link to="/products" className='link-sous-navbar'> Abonnements </Link>
                <span>/</span>
                <Link to={`/subscription/${product._id}`} className="link-sous-navbar" > Détails</Link>
                <span>/</span>
                <Link to={`/subscription/${product._id}`} className="link-sous-navbar" id='activeLink'> Informations </Link>

            </div>
            <div className='subscription-content-layout interne-subscription'>
                <div className='promo-code' onClick={() => ActifDiscount("promo")}>
                    <div className='details-promo-code'>
                        <FontAwesomeIcon icon={faTicket} className='coupon-icon' />
                        <h4 > Appliquer un code promo</h4>
                    </div>
                    <h3>{`>`}</h3>
                </div>
                {
                    codePromo &&
                    (<div className='add-promo-code'>
                        <input type="text" placeholder='Code' onChange={(e) => handleApplyPromoOrPoints(e, "promo")} />
                        <button onClick={applyDiscount}>Appliquer</button>

                    </div>)
                }
                <div className='promo-code' onClick={() => ActifDiscount("points")}>
                    <div className='details-promo-code'>
                        <FontAwesomeIcon icon={faGift} className='coupon-icon' />
                        <h4 > Appliquer Mes points</h4>
                    </div>
                    <h3  >{`>`}</h3>
                </div>
                {
                    pointsWin &&
                    (<div className='add-promo-code'>
                        <input type="number" placeholder='points' onChange={(e) => handleApplyPromoOrPoints(e, "points")} />
                        <button onClick={() => applyDiscountPoints()}>Appliquer</button>

                    </div>)
                }
                <h3>Détails de la commande</h3>
                <div className='img-product'>
                    <img src={`${config.img_url}/${product.productImage}`} alt='img-product' />
                </div>
                <div className='points-win'>
                    <span>Points gagné :</span>
                    <div className='points-win-details'>
                        <FontAwesomeIcon icon={faGift} className='iocn-win' />
                        <h4> {winPoints()} </h4>
                    </div>
                </div>
                <div className='subscription-options'>

                    <div className='subscription-options-details'>

                        <div>
                            <h3> {product.productName} {optionSub}</h3>
                            {promoApplied ?
                                (
                                    <div>
                                        {!smarTvPrice && <h3 className='style-price' style={{ textDecoration: 'line-through red', color: "gray" }}> FCFA {product.productPrice} </h3>}
                                        {!smarTvPrice && <h3 className='style-price'> FCFA {product.productPrice - discountedPrice} </h3>}
                                        {smarTvPrice && <h3 className='style-price' style={{ textDecoration: 'line-through red', color: "gray" }}> FCFA {smarTvPrice} </h3>}
                                        {smarTvPrice && <h3 className='style-price'> FCFA {smarTvPrice - discountedPrice} </h3>}
                                    </div>
                                ) :
                                (
                                    <div>
                                        {!smarTvPrice && <h3 className='style-price'> FCFA {product.productPrice} </h3>}
                                        {smarTvPrice && <h3 className='style-price'> FCFA {smarTvPrice} </h3>}
                                    </div>
                                )

                            }                        </div>

                        <div className='counter'>
                            <button onClick={decrement} className='counter-params'>
                                -
                            </button>
                            <button className='counter-params' id='quantity'>
                                {quantity}
                            </button>
                            <button onClick={increment} className='counter-params'>
                                +
                            </button>
                        </div>
                    </div>

                </div>

                <hr />

                <div className='subscription-options-details sub-total'>
                    <p>Sub Total </p>
                    {promoApplied ?
                        (
                            <div>
                                {!smarTvPrice && <h3 > FCFA {(product.productPrice - discountedPrice) * quantity} </h3>}
                                {smarTvPrice && <h3 > FCFA {(smarTvPrice - discountedPrice) * quantity} </h3>}
                            </div>
                        ) :
                        (
                            <div>
                                {!smarTvPrice && <h3> {product.productPrice * quantity} FCFA </h3>}
                                {smarTvPrice && <h3> {smarTvPrice * quantity} FCFA </h3>}
                            </div>
                        )

                    }
                </div>

                <div className='subscription-options-details frais-payment'>
                    <p>Frais Paiment </p>
                    <h3> 2%</h3>
                </div>

                <div className='subscription-options-details sub-total'>
                    <p> Total </p>
                    {promoApplied ?
                        (
                            <div>
                                {!smarTvPrice && <h3 className='total-price' > FCFA {((product.productPrice - discountedPrice) * quantity) + (product.productPrice * 0.02)} </h3>}
                                {smarTvPrice && <h3 className='total-price' > FCFA {((smarTvPrice - discountedPrice) * quantity) + (product.productPrice * 0.02)} </h3>}
                            </div>
                        ) :
                        (
                            <div>
                                {!smarTvPrice && <h3 className='total-price'> {(product.productPrice * quantity) + (product.productPrice * 0.02)} FCFA </h3>}
                                {smarTvPrice && <h3 className='total-price'> {(smarTvPrice * quantity) + (product.productPrice * 0.02)} FCFA </h3>}
                            </div>
                        )
                    }
                </div>

                <div className=''>
                    {statusLicenseToReserve === false && product.productCategory !== "Cartes Cadeaux et Jeux Video" && <p className='sub-inprogress' onClick={() => handleChangeStatus()} >Avez vous un compte existant que vous souhaitez Conserver ?</p>}
                </div>
                {
                    statusLicenseToReserve === true &&
                    (

                        <div className="form-reserve-license" >
                            <p className='sub-inprogress' onClick={() => handleChangeStatus()}>Je préfére avoir de nouveaux Accès !!!</p>
                            <p> Veuillez indiquer ici les identifiants du compte que vous souhaitez renouveler.</p>
                            <p style={{ "color": "red", "fontSize": "13px" }}> Attention! Ce compte est le compte de votre abonnement que vous souhaitez rénouveler et non  l'email avec lequel vous vous connecter sur l'application Senpremium.</p>
                            <div className='details-form-reserve-license'>
                                <label>Email du compte existant à renouveler</label>
                                <input
                                    name="subscriptionEmailKey"
                                    value={subscriptionEmailKey}
                                    type="email"
                                    onChange={(e) => handleChange(e)}

                                />
                            </div>
                            <div className='details-form-reserve-license'>
                                <label>Mot de passe du compte existant à renouveler</label>
                                <input
                                    name="subscriptionPWKey"
                                    value={subscriptionPWKey}
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    )
                }

                <button onClick={handleSubscriptionRequest} className='validate-subscription-btn' id='btn-payer' disabled={paymentInProgress}>
                    {paymentInProgress ? 'Traitement en cours...' : 'Payer'}
                </button>

            </div>
            <div className='footer'>
                <Footer />
            </div>
        </div>
    );
}

export default NewSubscription;
