import React, { useContext } from 'react';
import NavBar from '../../Layouts/NavBar';
import { DataContext } from '../../Services/DataContext';
import { Link } from 'react-router-dom';


function ShareDetails() {
  const { formShare, handleChange, setActiveComponent } = useContext(DataContext);

  // Fonction pour mettre à jour la valeur de shareType
  const handleShareTypeChange = (event) => {
    handleChange('shareType', event.target.value);
  };

  // Fonction pour mettre à jour la valeur de shareQuantity
  const handleShareQuantityChange = (event) => {
    handleChange('shareQuantity', event.target.value);
  };

  // Fonction pour mettre à jour la valeur de shareEmailProduct
  const handleShareEmailChange = (event) => {
    handleChange('shareEmailProduct', event.target.value);
  };

  // Fonction pour mettre à jour la valeur de sharePasseWordProduct
  const handleSharePasswordChange = (event) => {
    handleChange('sharePasseWordProduct', event.target.value);
  };

  // Une fonction pour changer le composant actif lorsque vous cliquez sur un bouton
  const handleButtonClick = (componentName) => {
    setActiveComponent(componentName);
  };


  return (
    <div className='share-details'>
      <div className='share-details-content share-layout'>
        <h2> Avec le partage de : <span> {formShare.shareName}</span> <br /> vous gagnerez: <span id='share-price'>{formShare.sharePrice} FCFA</span>  / mois</h2>
        <div className='share-details-infos'>
          <form>
            <div className='form-child'>
              <div>
                <label>Type d'abonnement</label>
                <input
                  type="text"
                  name='shareType'
                  onChange={handleShareTypeChange}
                  value={formShare.shareType}
                />
              </div>
              <div>
                <label>Nombre de profils disponibles</label>
                <input
                  type="number"
                  name='shareQuantity'
                  onChange={handleShareQuantityChange}
                  value={formShare.shareQuantity}
                />
              </div>
            </div>
            <div className='form-child'>
              <div>
                <label>Email du Compte à partager</label>
                <input
                  type="text"
                  name='shareEmailProduct'
                  onChange={handleShareEmailChange}
                  value={formShare.shareEmailProduct}
                />
              </div>
              <div>
                <label>Mot de passe du Compte à partager</label>
                <input
                  type="text"
                  name='sharePasseWordProduct'
                  onChange={handleSharePasswordChange}
                  value={formShare.sharePasseWordProduct}
                />
              </div>
            </div>
          </form>
        </div>
        <div className='btn-change-component'>
            <Link to=''
              onClick={() => handleButtonClick('share-type')}
              id='card_active'
              className='btn-next'
            >
              Précedent
            </Link>

            <Link to=''
              onClick={() => handleButtonClick('share-resume')}
              id='card_active'
              className='btn-next'
            >
              Suivant
            </Link>
          </div>
      </div>
    </div>
  );
}

export default ShareDetails;
