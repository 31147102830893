import React, { useState, useEffect } from 'react';
import login from "../../Assets/Imgs/logoLogin.png";
import "../../Assets/Styles/Connexion.css";
import { Link, useNavigate } from "react-router-dom";
import Instance from '../../Services/Instance';
import config from '../../Services/config.js';


const OtpVerification = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Récupérer le token de l'URL
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    if (token) {
      // Envoyer le token au back-end pour la vérification
      Instance
        .get(`${config.api_url}/verify-email/${token}`)
        .then((response) => {
          // console.log(response.data.message);
          // Rediriger l'utilisateur vers la page de confirmation ou login
          navigate('/signin'); // Redirection vers la page de connexion
        })
        .catch((error) => {
          console.error('Erreur de vérification :', error.response.data.message);
          // Afficher un message d'erreur ou rediriger l'utilisateur
          navigate('/error-page'); // Redirection vers une page d'erreur
        });
    }
  }, [navigate]);

  return (
    <div className="otp-container">
      <div className="otp-left">
        <div>
          <h1>Email Verification</h1>
          <p>Votre email a été verifié avec success !!!</p>
        </div>
        <form>
          <button type="submit" className="otp-submit-btn">
            <Link to="/signin" className="otp-submit-btn"> Se connecter</Link>
          </button>
        </form>


      </div>
      <div className="otp-right">
        <div className="otp-image-wrapper">
          <img src={login} alt="Senpremium Design" />
        </div>
        <h2>+100 Services Disponibles</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur. Semper dui nulla nulla odio.
        </p>
      </div>
    </div>
  );
};

export default OtpVerification;
