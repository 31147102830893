import React, { useEffect, useState } from 'react';
import Instance from '../../Services/Instance';
import config from "../../Services/config.js";
import { decryptData } from '../../Services/EncryptData.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom"
import deleteProduct from "../../Assets/Imgs/icons/icon-delet-item.svg";

function WishList() {
    const [wishlist, setWishlist] = useState(null);
    const [error, setError] = useState("");
    const [dataUser__, setDataUser__] = useState(null);

    useEffect(() => {
        const user__ = localStorage.getItem("user");
        if (user__) {
            const decryptedUser = decryptData(user__);
            setDataUser__(decryptedUser);
        }
    }, []);

    const fetchWishlist = async () => {
        try {
            if (dataUser__) {
                const result = await Instance.get(`${config.api_url}/wish-list/${dataUser__._id}`);
                setWishlist(result.data.wishlist);
            }
        } catch (error) {
            setError("Erreur lors de la récupération de la liste de souhaits.");
            console.log(error);
        }
    };
    
    useEffect(() => {
        fetchWishlist();
    }, [dataUser__]);

    const removeFromWishlist = async (productId) => {
        try {
            await Instance.delete(`${config.api_url}/remove-wish-product`, { data: { userId: dataUser__.id, productId } });
            setWishlist(wishlist.filter(item => item?.product?._id !== productId));
        } catch (error) {
            setError("Erreur lors de la suppression du produit de la liste de souhaits.");
            console.log(error);
        }
    };

    const handleRemove = async (product_id) => {
        try {
            await Instance.delete(`${config.api_url}/remove-wish-product`, {
                data: { userId: dataUser__._id, productId: product_id }
            });
            setWishlist(wishlist.filter(item => item?.product?._id !== product_id));
        } catch (error) {
            setError("Erreur lors de la suppression du produit de la liste de souhaits.");
            console.log(error);
        }
    };

    if (!wishlist || wishlist.length === 0) {
        return <h3>Aucun produit dans la liste de souhaits.</h3>;
    }

    return (
        <div className="wishlist">
            <h2>Votre liste de souhaits</h2>
            <div className='wish-list-content'>
                {wishlist.map((item, index) => (
                    <div className='list-elements custom-list-details' key={index}>
                        <div className='custom-list-details-card'>
                            <div className='delete-product-in-wishList'>
                                <img src={deleteProduct} alt="deleteProduct" className='deleteToWishList' onClick={() => handleRemove(item?.product?._id)} />
                            </div>
                            <div className='card-img'>
                                {item?.product?.productImage && (
                                    <img src={`${config.img_url}/${item.product.productImage}`} alt="img" className='img-product-list' />
                                )}
                            </div>
                            <div className='card-details-elements'>
                                <p id='type-sub'>{item?.product?.productType || "Type non spécifié"}</p>
                                <h4>{item?.product?.productName || "Nom non spécifié"}</h4>
                                <div className='price-product'>
                                    <span>{item?.product?.productPrice} FCFA </span>
                                    {item?.product?.productName && item?.product?.productName.toLocaleLowerCase().includes("netflix")
                                        && !item.product.productName.includes("NETFLIX Pack Family")
                                        && !item.product.productName.includes("Netflix Duo")
                                        && <span> {` --${item.product.productPrice2} FCFA`} </span>}
                                </div>
                                {item?.product?.productInStock && item?.product?.productInStock === "Out of Stock" ? (
                                    <div className='product-rupture'> En Rupture </div>
                                ) : (
                                    <div id='btn-subscription'>
                                        {(item?.product?.productCategory.includes("Cartes Cadeaux")  || item?.product?.productCategory === "Gaming") ? (
                                            <Link to={`/subscription/${item?.product?._id}`} className='link-subscription' id='btn-banner'> Acheter </Link>
                                        ) : (
                                            <Link to={`/subscription/${item?.product?._id}`} className='link-subscription' id='btn-banner'> S'abonner </Link>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {error && <p>{error}</p>}
        </div>
    );
}

export default WishList;
