import React from 'react'
import { Link } from 'react-router-dom';
import config from "../../Services/config.js";
import "../../Assets/Styles/MainLayout.css";
import "../../Assets/Styles/Home.css";
import "../../Assets/Styles/Products.css";
import listElementsScrolling from '../Home/ListElementsScrolling.js';

function CustomProductsCategories({ items }) {
    // Fonction pour déterminer si un produit est populaire
    const isPopular = (productName) => {
        return listElementsScrolling.some((item) => item.productName === productName);
    };
    return (
        <div className=''>
            <div className='custom-products-categories'>
                {items.map((item, index) => (
                    <div className='list-elements custom-list-details' key={index}>
                        <div className='custom-list-details-card'>
                            <div className='card-img'>
                                <Link
                                    to={item.productInStock && item.productInStock === "Out of Stock" ? "#" : `/subscription/${item._id}`}
                                >
                                    <img src={`${config.img_url}/${item.productImage}`} alt="img" className='img-product-list' />
                                </Link>
                            </div>
                            {item.promo && item.promo.InPromo ? (
                                <div className='status-products'>En promo</div>
                            ) : isPopular(item.productName) ? (
                                <div className='status-products'>Populaire</div>
                            ) : null}
                            <div className='card-details-elements'>
                                <p id='type-sub'>{item.productType}</p>
                                <h4>{item.productName}</h4>
                                <div className='price-product'>
                                    <span>{item.productPrice}FCFA </span>
                                    {item.productName.toLocaleLowerCase().includes("netflix")
                                        && !item.productName.includes("NETFLIX Pack Family")
                                        && !item.productName.includes("Netflix Duo")
                                        && <span> {` --${item.productPrice2} FCFA`} </span>}
                                </div>
                                {item.productInStock && item.productInStock === "Out of Stock" ?
                                    <div className='product-rupture'> En Rupture </div>
                                    :
                                    (<div id='btn-subscription'>
                                        {(item.productCategory.includes("Cartes Cadeaux")  || item.productCategory === "Gaming") ? (
                                            <Link to={`/subscription/${item._id}`} className='link-subscription' id='btn-banner'> Acheter </Link>
                                        ) : (
                                            <Link to={`/subscription/${item._id}`} className='link-subscription' id='btn-banner'> S'abonner </Link>
                                        )}
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CustomProductsCategories;