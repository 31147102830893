import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Instance from "../../Services/Instance";
import config from "../../Services/config.js";
import { DataContext } from "../../Services/DataContext";
import login from "../../Assets/Imgs/logoLogin.png";
import { encryptData } from "../../Services/EncryptData.js";
import "../../Assets/Styles/Connexion.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoginGoogle from "./LoginGoogle.js";
import logo from '../../Assets/Imgs/icons/logo senpremium.png';


const Login = () => {
    const [data, setData] = useState({ email: "", password: "" });
    const [error, setError] = useState("");
    const { toggleBooleanValue } = useContext(DataContext);
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from || "/";

    useEffect(() => {
        checkTokenExpiration();
        handleRedirectAfterReload();
    }, []);

    const checkTokenExpiration = () => {
        const tokenExpiration = localStorage.getItem("tokenExpiration");
        if (tokenExpiration) {
            const expirationTime = parseInt(tokenExpiration);
            const currentTime = new Date().getTime();
            if (currentTime > expirationTime) {
                localStorage.removeItem("token");
                localStorage.removeItem("tokenExpiration");
                localStorage.removeItem("user");
            }
        }
    };

    const handleRedirectAfterReload = () => {
        const redirectTo = localStorage.getItem("redirectTo");
        if (redirectTo) {
            localStorage.removeItem("redirectTo");
            navigate(redirectTo, { replace: true });
        }
    };

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const url = `${config.api_url}/signin`;
            const { data: response } = await Instance.post(url, data);

            if (response) {
                const user = response.user;
                if (user) {
                    setError("");
                    toggleBooleanValue();
                    const encryptUser = encryptData(user);
                    localStorage.setItem("token", response.token);
                    localStorage.setItem("user", encryptUser);
                    const expiresIn = 86400;
                    const expirationTime = new Date().getTime() + expiresIn * 1000;
                    localStorage.setItem("tokenExpiration", expirationTime);
                    localStorage.setItem("redirectTo", from);
                    window.location.reload();
                }
            } else {
                setError("Réponse invalide du serveur");
            }
        } catch (error) {
            console.error("Erreur lors de la connexion:", error);
            setError(error);
        }
    };

    //Connection with google 
    const GoogleWrapper = () => (
        <GoogleOAuthProvider clientId={config.client_id_google}>
            <LoginGoogle></LoginGoogle>
        </GoogleOAuthProvider>
    )


    return (
        <div class="container-login">
            <div class="left-section-login">
                <div className='logo-login'>
                    <Link to='/'>
                        <img src={logo} alt='logo' />
                    </Link>
                </div>
                <form >
                    <div>
                        <h1>Connexion</h1>
                        <p>Pour continuer, veuillez fournir les détails requis ci-dessous.</p>
                    </div>
                    <div className="parent-form-input">
                        <label htmlFor="email">Email:</label>
                        <input
                            className="input"
                            type="email"
                            name="email"
                            id="email"
                            value={data.email}
                            onChange={handleChange}
                            required
                            placeholder="Entrer votre Adrese Email"
                        />
                    </div>
                    <div className="parent-form-input">
                        <label htmlFor="password">Mot de passe:</label>
                        <input
                            className="input"
                            type="password"
                            name="password"
                            id="password"
                            value={data.password}
                            onChange={handleChange}
                            required
                            placeholder="Entrer votre Password"
                        />
                        {error && (
                            <h6 style={{ color: "red" }}>{error.response.data.message}</h6>
                        )}
                        <Link to="/resetpassword" className="reset-link">Mot de passe oublier ?</Link>
                    </div>
                    <button onClick={handleSubmit} class="btn">
                        Se Connecter
                    </button>
                </form>
                <p className="para1">Ou continuer avec Google</p>

                <button class="btn-google">
                    <GoogleWrapper />
                    {/*
                        Connexion avec Google*/}
                </button>

                <div className='signIn'>
                    <p id=''>Vous N’avez pas de compte ? </p>
                    <Link className="reset-link" to='/signup'>
                        Inscrivez-vous
                    </Link>
                </div>
            </div>

            <div class="right-section-login">
                <div class="image-container">
                    <img src={login} alt="Senpremium Design" />
                </div>
                <h2>+100 Services Disponibles</h2>
                <p id="para-login">
                    Rejoignez la meilleure application d'abonnements et profitez pleinement de vos services préférés en partageant ou rejoignant un service.
                    Spotify, Netflix, Amazon, Scribd, Appel Music, Mircosoft 365, Udemy, Iptv, etc...
                </p>
            </div>
        </div>

    );
};

export default Login;
