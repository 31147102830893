import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import music from "../../Assets/Imgs/icons/icon-music2.svg"
import video from "../../Assets/Imgs/icons/icon-tv2.svg"
import education from "../../Assets/Imgs/icons/icon-education2.svg"
import security from "../../Assets/Imgs/icons/icon-security2.svg"
import design from "../../Assets/Imgs/icons/icon-design.svg"
import home from "../../Assets/Imgs/icons/icon-home2.svg"
import application from "../../Assets/Imgs/icons/icon-app2.svg"
import carte from "../../Assets/Imgs/icons/icon-carte-cadeau.svg"
import { DataContext } from '../../Services/DataContext';

function ShareCategory() {

  const { handleChange, setActiveComponent, activeItem, setActiveItem } = useContext(DataContext);

  // Fonction pour mettre à jour la valeur de CategoryName
  const handleCategorySelection = (categoryName, numberItem) => {
    handleChange('CategoryName', categoryName);
    setActiveItem(numberItem)
  };

  // Une fonction pour changer le composant actif lorsque vous cliquez sur un bouton
  const handleButtonClick = (componentName) => {
    setActiveComponent(componentName);
  };

  // const theCathegories = ["music", "video", "logiciel", "jeux", "design", "carteCadeauEtJeux ", "education", "home"]

  return (
    <div className='share-category'>
      <div className='share-category-content share-layout'>
        <h2>Choisissez la catégorie de votre abonnement </h2>
        <div className='content-items'>

          <div
            className="card-item"
            id={activeItem === 1 ? 'card-active' : ""}
            onClick={() => { handleCategorySelection('music', 1); handleButtonClick('share-category') }}
          >
            <img src={music} alt="img-category" />
            <h4>music</h4>
          </div>

          <div 
            className='card-item'
            id={activeItem === 2 ? 'card-active' : ""}
            onClick={() => { handleCategorySelection('video', 2); handleButtonClick('share-category') }}
          >
            <img src={video} alt="img-category" />
            <h4>video</h4>
          </div>

          {/*<div 
            className='card-item'
            id={activeItem === 3 ? 'card-active' : ""}
            onClick={() => { handleCategorySelection('logiciel', 3); handleButtonClick('share-category') }}
          >
            <img src={application} alt="img-category" />
            <h4>logiciel</h4>
          </div>

          <div 
            className='card-item'
            onClick={() => { handleCategorySelection('Cartes Cadeaux et Jeux Video',4); handleButtonClick('share-category') }}
            id={activeItem === 4 ? 'card-active' : ""}
          >
            <img src={carte} alt="img-category" />
            <h4>Carte Cadeau & Jeux</h4>
        </div>*/}

          <div 
            className='card-item' 
            onClick={() => { handleCategorySelection('design',5); handleButtonClick('share-category') }}
            id={activeItem === 5 ? 'card-active' : ""}
          >
            <img src={design} alt="img-category" />
            <h4>design</h4>
          </div>

          <div 
            className='card-item' 
            onClick={() => { handleCategorySelection('security',6); handleButtonClick('share-category') }}
            id={activeItem === 6 ? 'card-active' : ""}
            >
            <img src={security} alt="img-category" />
            <h4>Security</h4>
          </div>

          <div 
            className='card-item' 
            onClick={() => { handleCategorySelection('education',7); handleButtonClick('share-category') }}
            id={activeItem === 7 ? 'card-active' : ""}
          >
            <img src={education} alt="img-category" />
            <h4>education</h4>
          </div>

          <div 
            className='card-item' 
            onClick={() => { handleCategorySelection('home',8); handleButtonClick('share-category') }}
            id={activeItem === 8 ? 'card-active' : ""}  
          >
            <img src={home} alt="img-category" />
            <h4>home</h4>
          </div>

        </div>

        <div className='btn-change-component'>
        <p>.</p>
          <Link to='' 
            onClick={() => handleButtonClick('share-type')}
            id={([1,2,3,4,5,6,7,8].includes(activeItem))  ? 'card_active' : ""}
            className='btn-next'
          >
            Suivant
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ShareCategory;
