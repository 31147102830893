import React, { useEffect, useState, useRef } from 'react';
import "../../Assets/Styles/Home.css";
import "../../Assets/Styles/Products.css";
import Categories from '../Category/ListCategory';

function CategoriesScroll({ setActiveSection }) {
    const [activeCategory, setActiveCategory] = useState(null);
    const carouselTrackRef = useRef(null);

    // useEffect(() => {
    //     const carouselTrack = carouselTrackRef.current;
    //     let autoScrollInterval;

    //     const startAutoScroll = () => {
    //         autoScrollInterval = setInterval(() => {
    //             if (carouselTrack.scrollLeft >= carouselTrack.scrollWidth / 2) {
    //                 carouselTrack.scrollLeft = 0;
    //             } else {
    //                 carouselTrack.scrollLeft += 1;
    //             }
    //         }, 10);
    //     };

    //     const stopAutoScroll = () => {
    //         clearInterval(autoScrollInterval);
    //     };

    //     startAutoScroll();

    //     carouselTrack.addEventListener("mouseenter", stopAutoScroll);
    //     carouselTrack.addEventListener("mouseleave", startAutoScroll);

    //     return () => {
    //         carouselTrack.removeEventListener("mouseenter", stopAutoScroll);
    //         carouselTrack.removeEventListener("mouseleave", startAutoScroll);
    //         stopAutoScroll();
    //     };
    // }, []);

    const _categories = Object.keys(Categories).map((key) => Categories[key]);

    const handleClick = (categoryHref) => {
        setActiveCategory(categoryHref);
        setActiveSection(categoryHref);
    };

    return (
        <div className='product-categories_scroller' ref={carouselTrackRef}>
            <div className='scroll-cat'>
                {
                    // _categories.concat(_categories).map((category, index) => (
                    _categories.concat(_categories).map((category, index) => (
                        <div key={index} className={`scroller__categories__items ${category.class_Name} ${activeCategory === category._href ? 'active' : ''}`}>
                            <a href={`#${category._href}`} onClick={() => handleClick(category._href)}>
                                <img src={category.img} alt="icon" />
                                {category.name}
                            </a>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default CategoriesScroll;
