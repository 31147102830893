import { useState } from "react";
import Instance from "../../Services/Instance.js";
import config from "../../Services/config.js";
import "../../Assets/Styles/Connexion.css";
import { Link } from "react-router-dom";
import login from "../../Assets/Imgs/logoLogin.png";
import iconGoogle from "../../Assets/Imgs/icons/icon-google.svg"
import logo from '../../Assets/Imgs/icons/logo senpremium.png';

const CreateAccount = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    numberPhone: "",
    email: "",
    password: "",
    role: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const checkbox = document.getElementById("checkbox");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (checkbox.checked) {
      try {
        const url = `${config.api_url}/signup`;

        const { data: response } = await Instance.post(url, data);
        alert("Un email de verification vous été envoyé pour valider votre Inscription. Veuillez consulter votre boite email ")
        window.location = "/signin";
      } catch (error) {
        console.error("Erreur lors de la connexion:", error);
        setError(error);
      }
    } else {
      alert(
        "Vous devez d'abord accepter les CGU de Senpremium avant de vous inscrir."
      );
    }
  };
  return (
    <div class="container-login">
      <div class="left-section-login">
        <div className='logo-login'>
          <Link to='/'>
            <img src={logo} alt='logo' />
          </Link>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="title-signup">
            <h1>Créer un Compte</h1>
            <p>Veuillez saisir les informations ci-dessous pour créer un compte</p>
          </div>
          <div className="parent-form-input">
            <label htmlFor="FirstName">Prénom</label>
            <input
              className="input"
              type="text"
              id="firstName"
              name="firstName"
              value={data.firstName}
              onChange={handleChange}
              required
              placeholder="Entrez votre prénom"
            />
          </div>
          <div className="parent-form-input">
            <label htmlFor="lastName">Nom</label>
            <input
              className="input"
              type="text"
              id="lastName"
              name="lastName"
              value={data.lastName}
              onChange={handleChange}
              required
              placeholder="Entre votre Nom"
            />
          </div>
          <div className="parent-form-input">
            <label htmlFor="numberPhone">Numéro de Téléphone (Whatsapp)</label>
            <input
              className="input"
              type="text"
              id="numberPhone"
              name="numberPhone"
              value={data.numberPhone}
              onChange={handleChange}
              required
              placeholder="Entrez Votre Numéro téléphone (whatsapp)"
            />
          </div>

          <div className="parent-form-input">
            <label htmlFor="email">Email:</label>
            <input
              className="input"
              type="email"
              name="email"
              id="email"
              value={data.email}
              onChange={handleChange}
              required
              placeholder="Enter Email Address"
            />
          </div>
          <div className="parent-form-input">
            <label htmlFor="password">Mot de passe:</label>
            <input
              className="input"
              type="password"
              name="password"
              id="password"
              value={data.password}
              onChange={handleChange}
              required
              placeholder="Enter Password"
            />
            {error && (
              <h6 style={{ color: "red" }}>{error.response.data.message}</h6>
            )}
          </div>
          <div id='cgu'>
            <div className='checkbox'>
              <input
                id='checkbox'
                name="CGU"
                type="checkbox"
                defaultChecked
              />
              <label>  En vous inscrivant vous acceptez les <Link to="/help-center/CGU"> CGU </Link>de Senpremium.</label>

            </div>
            <p>Vos informations personnelles  seront utilisées pour valider vos commandes. </p>
          </div>
          <button type="submit" class="btn">
            S'inscrire
          </button>
        </form>
      </div>

      <div class="right-section-login">
        <div class="image-container">
          <img src={login} alt="Senpremium Design" />
        </div>
        <h2>+100 Services Disponibles</h2>
        <p id="para-login">
          Rejoignez la meilleure application d'abonnements et profitez pleinement de vos services préférés en partageant ou rejoignant un service.
          Spotify, Netflix, Amazon, Scribd, Appel Music, Mircosoft 365, Udemy, Iptv, etc...
        </p>
      </div>
    </div>

  );
};

export default CreateAccount;
