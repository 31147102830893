import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../Services/DataContext';
import NavBar from '../../Layouts/NavBar';
import config from '../../Services/config';
import { Link } from 'react-router-dom';

function ShareType() {
  const { formShare, handleChange, setActiveComponent, activeName, setActiveName, video, music, logiciel, services, security, design, carteCadeauEtJeux, education, home } = useContext(DataContext);
  const [listProduct, setListProduct] = useState([]);

  useEffect(() => {
    const categoryType = formShare.CategoryName;
    let productList = [];

    switch (categoryType) {
      case "music":
        productList = music;
        break;
      case "video":
        productList = video;
        break;
      case "logiciel":
        productList = logiciel;
        break;
      case "services":
        productList = services;
        break;
      case "security":
        productList = security;
        break;
      case "design":
        productList = design;
        break;
      case "Cartes Cadeaux et Jeux Video":
        productList = carteCadeauEtJeux;
        break;
      case "education":
        productList = education;
        break;
      case "home":
        productList = home;
        break;
      default:
        productList = video;
        break;
    }

    setListProduct(productList);
  }, [formShare.CategoryName, music, video, logiciel, services, security, design, carteCadeauEtJeux, education, home]);

  
  const handleProduct = (product ,numberItem) => {
    // console.log(product);
    formShare.shareName = product.productName
    formShare.shareImg = `${product.productImage}` 
    formShare.sharePrice = ((product.productPrice*8.5)/10) // 85% du prix de l'article
    setActiveName(numberItem);

  };


  const handleButtonClick = (componentName) => {
    setActiveComponent(componentName);
  };

  // console.log( "++++++++",formShare);
  return (
    <div className='share-type'>
      <div className='share-type-content share-layout'>
        <h2>Choissisez  le type  d’abonnement sur ce cathalogue  </h2>
        <div className='content-items'>
          {listProduct.map((product, index) => (
            <div key={index} id={activeName === index + 1 ? 'card-active' : ""} className='card-item' onClick={() => { handleProduct(product,index + 1) }}>
              <img src={`${config.img_url}/${product.productImage}`} alt="img-category" />
              <h4>{product.productName}</h4>
              <div className='price-img-share-value'>

              </div>
            </div>
          ))}
        </div>
        <div className='btn-change-component'>
          <Link to='' onClick={() => handleButtonClick('share-category')} id='card_active' className='btn-next'>Précedent</Link>
          <Link to='' onClick={() => handleButtonClick('share-details')} id={([1, 2, 3, 4, 5, 6, 7, 8].includes(activeName)) ? 'card_active' : ""} className='btn-next'>Suivant</Link>
        </div>
      </div>
    </div>
  );
}

export default ShareType;
