import React, { useContext } from 'react'
import NavBar from '../Layouts/NavBar'
import { Link } from 'react-router-dom'
import ShareCategory from '../Components/Shares/ShareCategory';
import ShareType from '../Components/Shares/ShareType';
import ShareDetails from '../Components/Shares/ShareDetails';
import ShareResume from '../Components/Shares/ShareResume';
import { DataContext } from '../Services/DataContext';
import "../Assets/Styles/Share.css"

function Share() {

    const {setActiveComponent , activeComponent} = useContext(DataContext)

    // Une fonction pour changer le composant actif lorsque vous cliquez sur un bouton
    const handleButtonClick = (componentName) => {
        setActiveComponent(componentName);
    };

    return (
        <div className='share'>
            <NavBar />
            <div className='share-content'>
                <div className='side-bar'>

                    <Link
                        to=""
                        onClick={() => handleButtonClick('share-category')}
                        className={activeComponent === "share-category" ? "nav-side-bar" : "link-inactive"}
                    >
                        1. Catégorie
                    </Link>

                    <Link
                        to=""
                        onClick={() => handleButtonClick('share-type')}
                        className={activeComponent === "share-type" ? "nav-side-bar" : "link-inactive"}
                    >
                        2. Nom
                    </Link>

                    <Link
                        to=""
                        onClick={() => handleButtonClick('share-details')}
                        className={activeComponent === "share-details" ? "nav-side-bar" : "link-inactive"}
                    >
                        3. Détails
                    </Link>

                    <Link
                        to=""
                        onClick={() => handleButtonClick('share-resume')}
                        className={activeComponent === "share-resume" ? "nav-side-bar" : "link-inactive"}
                    >
                        4. Resumé
                    </Link>

                </div>

                <div className='share-components'>

                    {activeComponent === 'share-category' && <ShareCategory />}
                    {activeComponent === 'share-type' && <ShareType />}
                    {activeComponent === 'share-details' && <ShareDetails />}
                    {activeComponent === 'share-resume' && <ShareResume />}

                </div>
            </div>
        </div>
    )
}

export default Share