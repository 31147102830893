import React, { useEffect, useState } from 'react'
import { Routes, Route, useResolvedPath, useMatch } from 'react-router-dom'; // Utilise Routes et Route
import { useParams } from 'react-router-dom'
import Instance from '../Services/Instance'
import config from "../Services/config.js"
import { decryptData } from '../Services/EncryptData.js'
import NavBar from '../Layouts/NavBar';
import SideBarUser from '../Layouts/SideBarUser';
import UserSubscription from '../Components/Compte/UserSubscription';
import UserShares from '../Components/Compte/UserShares';
import EditInfos from '../Components/Compte/EditInfos';
import WishList from '../Components/Compte/WishList.js';



function Account() {
    let resolvedPath = useResolvedPath();
    let match = useMatch({ path: resolvedPath.pathname, end: true });

    const user__ = localStorage.getItem("user");
    const dataUser__ = user__ === null ? null : decryptData(user__);
    
    // console.log(dataUser__._id);
    if (!dataUser__ || !user__ ) {
        return <div>Loading...</div>
    }
    return (
        <div className='account'>
            <NavBar />
            <div className='profile'>
                <SideBarUser />
                <div className='content-profile'>
                    {/* Gère les routes imbriquées */}
                    <Routes>
                        <Route path="/" element={<UserSubscription />} /> {/* Page par défaut */}
                        <Route path="/user-subscriptions" element={<UserSubscription />} />
                        <Route path={`/edit-user/${dataUser__._id}`} element={<EditInfos />} />
                        <Route path="/user-shares" element={<UserShares />} />
                        <Route path="/wishlist" element={<WishList />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default Account;
