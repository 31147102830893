import React, { useContext, useEffect, useState, useRef } from 'react';
import '../Assets/Styles/MainLayout.css'; // Fichier CSS pour le style
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faShare, faUserPlus, faHeart, faUser, faShoppingCart, faTrashAlt, faSignOutAlt, faGift } from '@fortawesome/free-solid-svg-icons';
import "../Assets/Styles/Connection.css";
import { Link } from 'react-router-dom';
import { decryptData } from '../Services/EncryptData.js';
import { DataContext } from '../Services/DataContext';
import Instance from '../Services/Instance.js';
import config from '../Services/config.js';

const SideBarUser = () => {
    const [userConnected, setUserConnected] = useState({
        firstName: '',
        lastName: '',
        numberPhone: '',
        email: '',
        point: "",
    });

    // Récupération de l'utilisateur stocké dans le localStorage
    const user__ = localStorage.getItem("user");
    const dataUser__ = user__ === null ? null : decryptData(user__);

    const { toggleBooleanValue } = useContext(DataContext);

    // Références pour chaque élément du menu
    const subscriptionsRef = useRef(null);
    const sharesRef = useRef(null);
    const wishlistRef = useRef(null);
    const cartRef = useRef(null);
    const profileRef = useRef(null);
    const deleteAccountRef = useRef(null);

    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('tokenExpiration');
        toggleBooleanValue();
        window.location = '/';
    };

    const fetchData = async () => {
        try {
            if (dataUser__ && dataUser__._id) {
                const result = await Instance.get(`${config.api_url}/show-user/${dataUser__._id}`);
                setUserConnected(result.data.user);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // Exécuter fetchData seulement si dataUser__ est valide
        if (dataUser__ && dataUser__._id) {
            fetchData();
        }
    }, [dataUser__?.id]); // Assurez-vous d'avoir une dépendance stable et non pas une référence directe à `dataUser__`

    // Fonction pour faire défiler l'élément dans la vue
    const handleScroll = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Retourner null si dataUser__ est null pour éviter de rendre le composant
    if (!dataUser__) {
        return null;
    }

    return (
        <div className="sidebar-user">
            <div className="profile-section">
                <img className="profile-pic" src="https://via.placeholder.com/100" alt="User" />
                <div className='background-profile'></div>
                <h3 className='user-name'>{`${dataUser__.firstName} ${dataUser__.lastName}` || "Nom d’utilisateur"}</h3>
                <div className='points-in-sidebar'>
                    <FontAwesomeIcon icon={faGift} className='coupon-icon' />
                    <h3 className='user-pts'>{`${userConnected.point} points` || `0 points`}</h3>
                </div>
            </div>

            <div className="menu-section">
                <h4>Mon Compte</h4>
                <ul className="menu-list">
                    <li ref={subscriptionsRef} onClick={() => handleScroll(subscriptionsRef)}>
                        <Link to="/account/user-subscriptions" className="icon-circle">
                            <FontAwesomeIcon icon={faBox} className="menu-icon" />
                            <span>Mes Commandes</span>
                        </Link>
                    </li>
                    <li ref={sharesRef} onClick={() => handleScroll(sharesRef)}>
                        <Link to="/account/user-shares" className="icon-circle">
                            <FontAwesomeIcon icon={faShare} className="menu-icon" />
                            <span>Partager un abonnement</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/products" className="icon-circle">
                            <FontAwesomeIcon icon={faUserPlus} className="menu-icon" />
                            <span>Joindre un abonnement</span>
                        </Link>
                    </li>
                    <li ref={wishlistRef} onClick={() => handleScroll(wishlistRef)}>
                        <Link to="/account/wishlist" className="icon-circle">
                            <FontAwesomeIcon icon={faHeart} className="menu-icon" />
                            <span>Ma Liste de Souhaits</span>
                        </Link>
                    </li>
                    <li ref={profileRef} onClick={() => handleScroll(profileRef)}>
                        <Link to={`/account/edit-user/${dataUser__._id}`} className="icon-circle">
                            <FontAwesomeIcon icon={faUser} className="menu-icon" />
                            <span>Modifier Profil</span>
                        </Link>
                    </li>
                    <li ref={cartRef} onClick={() => handleScroll(cartRef)}>
                        <Link to="/cart" className="icon-circle">
                            <FontAwesomeIcon icon={faShoppingCart} className="menu-icon" />
                            <span>Mon Panier</span>
                        </Link>
                    </li>
                    <li ref={deleteAccountRef} onClick={() => handleScroll(deleteAccountRef)}>
                        <Link to="" className="icon-circle">
                            <FontAwesomeIcon icon={faTrashAlt} className="menu-icon" id="delete-account" />
                            <span id="delete-account">Supprimer Compte</span>
                        </Link>
                    </li>
                </ul>
            </div>

            <div className="logout-section">
                <button onClick={handleLogout} className="icon-circle">
                    <FontAwesomeIcon icon={faSignOutAlt} className="menu-icon" />
                    <span>Déconnexion</span>
                </button>
            </div>
        </div>
    );
};

export default SideBarUser;
