import React, { useEffect, useState } from 'react'
import Footer from '../../Layouts/Footer'
import NavBar from '../../Layouts/NavBar'
import Instance from '../../Services/Instance'
import config from "../../Services/config.js"
import { useParams } from 'react-router-dom'

function Post() {
    const [post, setPost] = useState({})
    const { id } = useParams()
    const fetchData = async () => {
        try {
            const result = await Instance.get(`${config.api_url}/post/${id}`)
            setPost(result.data.post)
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [id])

    return (
        <div className='post'>
            <NavBar />
            <div className='post-content' >
                <div className='post-title'>
                    <img src={`${config.img_url}/${post.productImage}`} alt="chatgpt" className='img-post' />
                    <div className='post-title-details'>
                        <h1 className='details-post-title'>{post.title} </h1>
                        <h3> {post.category} </h3>
                        <h3> {post.introduction && post.introduction} </h3>
                    </div>
                </div>
                <div>
                </div>
                <div className='post-details'>
                    <div className='description-card'>
                        <br /> <br />
                        <h1 className='title-card'>{post.title} </h1>
                        <br/>
                        <p id='text'>
                            {post.content}
                        </p>
                        {
                            post.subsections && post.subsections.map((subsection, index) => (
                                <div key={index} className='subsection'>
                                    <h3> {subsection.subtitle}</h3>
                                    <p> {subsection.content}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}

export default Post