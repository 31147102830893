import React, { useState } from 'react';
import "../../Assets/Styles/Home.css"

const HowToWork2 = () => {
    const [isSharing, setIsSharing] = useState(false);

    const handleToggleContent = () => {
        setIsSharing(!isSharing);
    };

    // Contenu pour les cartes
    const content = isSharing
        ? [
            {
                step: "01",
                title: "Connexion",
                description: "Pour pouvoir faire un partage il faut dabord vous connecter. Si vous n'avez pas de Compte vous pouvez en créer un."
            },
            {
                step: "02",
                title: "Accueil",
                description:
                    "Sur la barre de navigation, appuyez sur ''Mon compte'' pour accéder à la page de  votre Profil. Appuyez en suite sur ''Mes partage'' du sidebar. ",
            },
            {
                step: "03",
                title: "Compte",
                description:
                    "Une fois sur la page de Partage de votre compte , appuye sur le bouton ''Partage''.",
            },
            {
                step: "04",
                title: "Infos & Partage",
                description:
                    "Il ne vous reste qu'a passez les différentes informations du produit que vous compter partager.",
            },
        ]
        : [
            {
                step: "01",
                title: "Choix",
                description:
                    "Choisissez à travers la page 'Abonnment' l'abonnement qui vous convient sur la large Gamme de Senpremium.",
            },
            {
                step: "02",
                title: "Produit",
                description:
                    "Vous aurez  en suite, tous les détails concernant le produit que vous avez choisi pour l'abonnment ou l'achat.",
            },
            {
                step: "03",
                title: "Infos",
                description:
                    "Passez vos informations perso, puis Choisissez les options de votre abonnement selon vos besoins.",
            },
            {
                step: "04",
                title: "Paiment",
                description:
                    "Effectuez enfin votre paiement en toute sécurité avec le moyen de paiement qui vous convient, et recevez vos accès instantanément.",
            },
        ];

    return (
        <div className="subscription-container2">
            <div className='sub_share_title'>
                <h1>Comment ça marche ?</h1>
                <p>En seulement 4 étape somples </p>
            </div>
            <div className="btn-container-how-work">
                <button className={`btn ${!isSharing ? "btn_active" : ""}`} onClick={() => setIsSharing(false)}>
                    Rejoindre un abonnement
                </button>
                <button className={`btn ${isSharing ? "btn_active" : ""}`} onClick={handleToggleContent}>
                    Partager un abonnement
                </button>
            </div>

            <div className="steps-container">
                {content.map((item, index) => (
                    <div key={index} className={`step-card card-${index + 1}`}>
                        <div className="circle">{item.step}</div>
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HowToWork2;
