import React from 'react';
import "../../Assets/Styles/HelpCenter.css";
import NavBar from '../../Layouts/NavBar';
import Footer from '../../Layouts/Footer';

function CGU() {
  window.scrollTo(0, 0);

  return (
    <div>
      <NavBar />
      <section id="condition">
        <div className="titre">
          <h1>Conditions générales d'utilisation de </h1>
        </div>
        <div className="date-et-text">
          <p>
            <span className="gras">Date de dernière mise à jour : <span className='last-update'>20 mai 2024</span></span>
          </p>
          <br />
          <p>
            Bienvenue sur Senpremium. En utilisant notre site web et nos services, vous acceptez les présentes Conditions Générales d'Utilisation (CGU). Veuillez lire attentivement ces conditions avant d'utiliser notre site.
          </p>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>1. Acceptation des Conditions</h2>
          </div>
          <div className="contenu-text">
            <p>
              En accédant et en utilisant le site <span>Senpremium</span>, vous acceptez de respecter et d'être lié par les présentes CGU. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre site.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>2. Description des Services</h2>
          </div>
          <div className="contenu-text">
            <p>
              <span>Senpremium</span> fournit des produits numériques, y compris mais sans s'y limiter, des comptes, des cartes cadeaux numériques, et des abonnements. Tous les produits disponibles à l'achat sur notre site sont livrés électroniquement.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>3. Capacité Juridique</h2>
          </div>
          <div className="contenu-text">
            <p>
              Le Site et les Services sont accessibles : <br /><br />
              <ul>
                <li>
                  À toute personne physique disposant de la pleine capacité juridique pour s’engager au titre des présentes conditions générales. La personne physique qui ne dispose pas de la pleine capacité juridique ne peut accéder au Site et aux Services qu’avec l’accord de son représentant légal. <br /><br />
                </li>
                <li>
                  À toute personne morale agissant par l’intermédiaire d’une personne physique disposant du pouvoir ou de l’habilitation requise pour contracter au nom et pour le compte de la personne morale.
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>4. Comptes Utilisateurs</h2>
          </div>
          <div className="contenu-text">
            <p>
              Pour accéder à certaines fonctionnalités de notre site, vous devrez créer un compte. Vous êtes responsable de maintenir la confidentialité de votre compte et de votre mot de passe, et vous acceptez d'informer immédiatement <span>Senpremium</span> de toute utilisation non autorisée de votre compte.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>5. Politique de Paiement</h2>
          </div>
          <div className="contenu-text">
            <p>
              Tous les paiements effectués sur <span>Senpremium</span> sont traités via des plateformes de paiement sécurisées. Vous acceptez de payer tous les frais associés à vos achats sur notre site.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>6. Politique de Remplacement et de Remboursement</h2>
          </div>
          <div className="contenu-text">
            <p>
              <span className="gras">1. Garantie en Cours de Validité :</span> Le compte doit être couvert par une garantie valide. <br /><br />
              <span className="gras">2. Type de Compte :</span> Le compte doit être un compte valide et fonctionnel. <br /><br />
              <span className="gras">3. Cartes Cadeaux et Abonnements :</span> Les cartes cadeaux numériques et les abonnements ne sont pas remboursables ou échangeables après la livraison, sauf en cas de problème technique où un remplacement n'est pas possible. Dans ce cas, un remboursement pour le montant restant peut être effectué. <br /><br />
              <span className="gras">4. Cas d'Échange :</span> Si un compte présente un problème qui ne peut être résolu ou remplacé, une demande de remboursement peut être initiée. Les remboursements sont traités sous un délai de 48 heures ouvrables. <br /><br />
              <span className="gras">5. Modalités de Remboursement :</span> En cas d’approbation d’un remboursement, seul le montant restant sera retourné. Veuillez noter qu’après 10 à 15 jours d’utilisation, un remboursement intégral n’est pas envisageable.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>7. Utilisation Acceptable</h2>
          </div>
          <div className="contenu-text">
            <p>
              Vous acceptez d'utiliser <span>Senpremium</span> uniquement à des fins légales et conformément aux présentes CGU. Vous vous engagez à ne pas utiliser notre site : <br /><br />
              <ul>
                <li>
                  Pour enfreindre toute loi ou réglementation applicable. <br /><br />
                </li>
                <li>
                  Pour exploiter, nuire ou tenter de nuire à des mineurs de quelque manière que ce soit. <br /><br />
                </li>
                <li>
                  Pour envoyer ou recevoir sciemment du contenu qui ne respecte pas les présentes CGU. <br /><br />
                </li>
              </ul>
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>8. Propriété Intellectuelle</h2>
          </div>
          <div className="contenu-text">
            <p>
              Tout le contenu de <span>Senpremium</span>, y compris mais sans s'y limiter, les textes, graphiques, logos, images, clips audio, et logiciels, est la propriété de <span>Senpremium</span> ou de ses fournisseurs de contenu et est protégé par les lois internationales sur les droits d'auteur et les marques.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>9. Limitation de Responsabilité</h2>
          </div>
          <div className="contenu-text">
            <p>
              <span>Senpremium</span> ne sera pas responsable des dommages directs, indirects, accessoires, spéciaux ou consécutifs résultant de l'utilisation ou de l'incapacité à utiliser notre site ou nos services, même si nous avons été informés de la possibilité de tels dommages.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>10. Modifications des Conditions</h2>
          </div>
          <div className="contenu-text">
            <p>
              <span>Senpremium</span> se réserve le droit de modifier ces CGU à tout moment. Nous vous informerons de tout changement en publiant les nouvelles conditions sur cette page. Votre utilisation continue de notre site après la publication des modifications constitue votre acceptation de ces changements.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>11. Résiliation</h2>
          </div>
          <div className="contenu-text">
            <p>
              Nous nous réservons le droit de suspendre ou de résilier votre accès à notre site et à nos services, sans préavis, pour toute violation des présentes CGU ou pour toute autre raison à notre discrétion.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>12. Contact</h2>
          </div>
          <div className="contenu-text">
            <p>
              Si vous avez des questions concernant ces CGU, veuillez nous contacter à l'adresse suivante : <br />
              <span>contact@senpremium.com</span>.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default CGU;
