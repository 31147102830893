import React from 'react';
import "../../Assets/Styles/HelpCenter.css";
import NavBar from '../../Layouts/NavBar';
import Footer from '../../Layouts/Footer';

function RefundPolicy() {
  window.scrollTo(0, 0);

  return (
    <div>
      <NavBar />
      <section id="politique">
        <div className="titre">
          <h1>Politique de Remplacement et de Remboursement</h1>
        </div>
        <div className="date-et-text">
          <p>
            <span className="gras">Date de dernière mise à jour : 20 mai 2024</span>
          </p>
          <br />
          <p>
            Nous vous remercions de votre confiance en choisissant Senepremium pour vos achats. Afin de garantir votre entière satisfaction, nous vous présentons notre politique de remplacement et de remboursement. Veuillez lire attentivement les conditions suivantes, applicables à tous les comptes achetés sur notre plateforme.
          </p>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>Critères d'Éligibilité pour Échange et Remboursement</h2>
          </div>
          <div className="contenu-text">
            <p>
              <span className="gras">1. Garantie en Cours de Validité :</span> Le compte doit être couvert par une garantie valide.<br /><br />
              <span className="gras">2. Type de Compte :</span> Le compte doit être un compte valide et fonctionnel.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>Cas d'Échange</h2>
          </div>
          <div className="contenu-text">
            <p>
              - Si un compte présente un problème qui ne peut être résolu ou remplacé, une demande de remboursement peut être initiée.<br /><br />
              - Les remboursements sont traités sous un délai de 48 heures ouvrables.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>Exclusions</h2>
          </div>
          <div className="contenu-text">
            <p>
              - <span className="gras">Cartes Cadeaux et Abonnements :</span> Les cartes cadeaux numériques et les abonnements ne sont pas remboursables ou échangeables après la livraison, sauf en cas de problème technique où un remplacement n'est pas possible. Dans ce cas, un remboursement pour le montant restant peut être effectué.<br /><br />
              - Les produits fonctionnant normalement ne sont pas éligibles pour un remboursement ou un échange.<br /><br />
              - En cas de non-respect de nos règlements, Senepremium se réserve le droit de supprimer un compte sans remboursement.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>Droits de Senepremium</h2>
          </div>
          <div className="contenu-text">
            <p>
              Nous nous réservons le droit de refuser un échange ou un remboursement si les conditions ci-dessus ne sont pas remplies.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>Modalités de Remboursement</h2>
          </div>
          <div className="contenu-text">
            <p>
              En cas d’approbation d’un remboursement, seul le montant restant sera retourné. Veuillez noter qu’après 10 à 15 jours d’utilisation, un remboursement intégral n’est pas envisageable.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>Politique de Remboursement des Abonnements</h2>
          </div>
          <div className="contenu-text">
            <p>
              Les abonnements, une fois livrés, ne sont pas éligibles pour un remboursement ou un échange, sauf en cas de problème technique où un remplacement n'est pas possible. Dans ce cas, un remboursement pour le montant restant peut être effectué. En achetant un abonnement sur notre site, tous les utilisateurs acceptent cette condition.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>Politique de Remboursement des Cartes Cadeaux Numériques</h2>
          </div>
          <div className="contenu-text">
            <p>
              Les services rendus dans un environnement électronique ou les produits de contenu numérique intangibles (jeux, clés CD, cartes cadeaux prépayées, etc.) livrés au consommateur ne peuvent pas être annulés ou retournés, sauf en cas de problème technique où un remplacement n'est pas possible. Dans ce cas, un remboursement pour le montant restant peut être effectué. En achetant sur notre site, tous les utilisateurs acceptent cette condition.
            </p>
          </div>
        </div>
        <div className="contenu">
          <div className="barre"></div>
          <div className="sous-titre">
            <h2>Senepremium garantit que tous les codes fournis sont fonctionnels</h2>
          </div>
          <div className="contenu-text">
            <p>
              En cas de problème, notre équipe technique est toujours disponible pour vous assister. Pour toute question concernant notre politique, veuillez consulter notre centre d’aide.<br /><br />
              <a href="mailto:Support@senepremium.com">Support@senepremium.com</a>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default RefundPolicy;