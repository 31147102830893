import iconMusic from "../../Assets/Imgs/icons/icon-music.png"
import iconVideo from "../../Assets/Imgs/icons/icon-tv.svg"
import iconGame from "../../Assets/Imgs/icons/icon-game.svg"
import iconApp from "../../Assets/Imgs/icons/icon-app.svg"
import iconSecurity from "../../Assets/Imgs/icons/icon-security.svg"
import iconEducation from "../../Assets/Imgs/icons/icon-education.svg"
import iconService from "../../Assets/Imgs/icons/icon-services.svg"
import iconDesign from "../../Assets/Imgs/icons/icon-design.svg"
const Categories = [
    {
        name: "Video",
        img: iconVideo,
        _href: "video",
        class_Name: "category-orange"

    },

    {
        name: "Music",
        img: iconMusic,
        _href: "music",
        class_Name: "category-blue"
    },
    {
        name: "Logiciel",
        img: iconApp,
        _href: "logiciel",
        class_Name: "category-orange"

    },

    {
        name: "Cartes Cadeaux et Jeux",
        img: iconGame,
        _href: "cartes",
        class_Name: "category-blue"

    },
    // {
    //     name : "Applications",
    //     img : iconApp ,
    //     _href : "logiciel",
    //     class_Name : "category-orange"

    // },
    {
        name: "Design",
        img: iconDesign,
        _href: "design",
        class_Name: "category-orange"

    },
    // {
    //     name : "Maison",
    //     img : iconHome ,
    //     _href : "home",
    //     class_Name : "category-orange"

    // },
    {
        name: "Education",
        img: iconEducation,
        _href: "education",
        class_Name: "category-blue"

    },

    {
        name: "Securité",
        img: iconSecurity,
        _href: "security",
        class_Name: "category-orange"

    },
    {
        name: "Services",
        img: iconService,
        _href: "services",
        class_Name: "category-blue"

    },


]

export default Categories