import React from 'react'
import NavBar from "../Layouts/NavBar"
import BannerProduct from '../Components/Products/BannerProduct'


function Products() {
    return (
        <div className='product-content'>
            <NavBar />
            <BannerProduct />
        </div>
    )
}

export default Products