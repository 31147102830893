import CryptoJS from 'crypto-js';

//clé à definir sur le .env

// Fonction pour chiffrer les données
function encryptData(data) {
    const key= "IBLHZpwDDgqNBk+MIUNFenG7h3Ko/vqyeytod0u/+pjD2K8GtVPIoy7j0J7IcKTv7pbeCzlvOop6zC06rRSXmieBqgPZbXbfF6ndg5Alas8="
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    return encryptedData;
}

// Fonction pour déchiffrer les données
function decryptData(encryptedData) {
    const key= "IBLHZpwDDgqNBk+MIUNFenG7h3Ko/vqyeytod0u/+pjD2K8GtVPIoy7j0J7IcKTv7pbeCzlvOop6zC06rRSXmieBqgPZbXbfF6ndg5Alas8="
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, key).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
}

export { encryptData, decryptData };
