import React, { useEffect, useState } from 'react'
import NavBar from '../../Layouts/NavBar'
import "../../Assets/Styles/Compte.css"
import { Link, useParams } from 'react-router-dom'
import Instance from '../../Services/Instance'
import config from "../../Services/config.js"
import inProgress from "../../Assets/Imgs/icons/gif-waiting.gif"

function UserShares() {
  const [shares, setShares] = useState([])
  const [shareToRequestPayment, setShareToRequestPayment] = useState(null)

  const { id } = useParams();

  const fetchData = async () => {
    try {
      const result = await Instance.get(`${config.api_url}/user-shares`)
      setShares(result.data.shares)
      // console.log(result);
    } catch (error) {
      console.log(error);
    }
  }

  const handleDataShare = async (idShare) => {
    try {
      const response = await Instance.get(`${config.api_url}/show-share/${idShare}`);
      // console.log(response.data.share);
      setShareToRequestPayment(response.data.share);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (shareToRequestPayment) {
      // console.log("++++++", shareToRequestPayment);
      handleRequestPayment(shareToRequestPayment._id);
    }
  }, [shareToRequestPayment]);

  const handleRequestPayment = async (id) => {
    try {
      if (shareToRequestPayment) {
        // console.log("++++", shareToRequestPayment);

        const result = await Instance.put(`${config.api_url}/update-share/${id}`, {
          ReqPaymentShare: "Request Payment",
        });

        // Mettre à jour l'état des shares avec la nouvelle valeur de ReqPaymentShare
        setShares((prevShares) =>
          prevShares.map((share) =>
            share._id === id ? { ...share, ReqPaymentShare: "Request Payment" } : share
          )
        );

        // console.log(result);
      }
      alert("Votre demande de paiement a été envoyée avec succès, Notre équipe traitera votre demande dans les meilleurs délais")
      window.location = "/account/user-shares";
    } catch (error) {
      // setError(error);
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [id])

  const newDate = new Date();
  const _date = newDate.toLocaleDateString('fr-FR')

  let sumPayment = 0;
  sumPayment = shares.reduce((sum, share) => sum + share.sharePrice, 0);
  const reversShares = shares.reverse()
  // console.log(reversShares);

  // console.log(shares);
  return (
    <div className='user-subscription'>
      <div className='user-subscription-content'>
        <div className='user-subscription-title '>
          <div className='title-content'>
            <h1>Abonnements Rejoins</h1>
            {/*<p>Solde Actuel : <span>12 000 XOF</span> </p>*/}
            <p>Date : <span>{_date}</span> </p>
            <p>Solde : <span>{sumPayment} F CFA</span> </p>
          </div>
        </div>

        <div className='user-subscription-title layout-content'>
          <div className='title-content'>
            <h1>Partagez un co-abonnement</h1>
            <p>Partager votre abonnement avec les utilisateurs de Senpremium et faites des économies</p>
          </div>
          <div className='btn-submit style-line'>
            <div className="vertical-line"></div>
            <Link to="/share" className='link-submit'>Partager</Link>
          </div>
        </div>

        {reversShares.map((share) => (
          <div key={share._id} className='suggestion-subscription layout-content'>
            <div className='first-content style-line'>
              <img src={`${config.img_url}/${share.shareImg}`} alt="img-shares" />
              <div className="vertical-line"></div>
            </div>
            <div className='second-content'>
              <h4> {share.shareName} </h4>
              <div>
                <p> Gain par mois: </p>
                <span> {share.sharePrice} FCFA </span>
              </div>
              <div>
                <p> Email du compte à partager: </p>
                <span> {share.shareEmailProduct} </span>
              </div>
              <div>
                <p> Mot de passe: </p>
                <span> {share.sharePasseWordProduct}</span>
              </div>
              <div>
                <p> Nombres de profils partagés: </p>
                <span> {share.shareQuantity} </span>
              </div>
            </div>
            <div className='three-content'>
              <div className='style-line'>
                <div className="vertical-line"></div>
                {share.status === "Not Validate" && share.ReqPaymentShare === "Not Request Payment" ? (
                  <div>
                    <p> Partage en cours </p>
                    <p>En entente de validation par l’Admin de Senpremium</p>
                    <img src={inProgress} alt="" />
                  </div>
                ) : share.status === "Validate" && share.ReqPaymentShare === "Not Request Payment" ? (
                  <div>
                    <p> Gains sur cet Abonnement : <span> {`${share.sharePrice} XOF`} </span> </p>
                    <button onClick={() => handleDataShare(share._id)} className='request-payment-share'> Demande de paiement</button>
                  </div>
                ) : share.status === "Validate" && share.ReqPaymentShare === "Request Payment" ? (
                  <div>
                    <p> Demande de paiement en cours </p>
                    <p>En entente de validation par l’Admin de Senpremium</p>
                    <img src={inProgress} alt="" />
                  </div>
                ) : share.status === "Decline" ? (
                  <div className="status-share">
                    <p> Partage décliner </p>
                  </div>
                ) : share.status === "Validate" && share.ReqPaymentShare === "Decline Payment" ?
                  <div className="status-share">
                    <p> Paiement Refusé </p>
                  </div>
                  : (
                    <div>
                      <p> Abonnement déjà payé </p>
                    </div>
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default UserShares;
